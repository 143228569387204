<template>
  <div>
    <b-table
      small
      striped
      responsive
      :fields="fields"
      :items="items"
    >
      <template
        #cell(check)="row"
      >
        <b-form-checkbox
          v-model="selected"
          :value="row.item"
        />
      </template>

      <template #cell(created_at)="data">
        {{ formatTimezone(data.item.created_at) }}
      </template>

      <template
        #cell(acoes)="data"
      >
        <b-row align-h="center">
          <b-col cols="auto">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="cursor-pointer"
              variant="outline-success"
              @click="aprovarTroca(data.item)"
            >
              <feather-icon
                icon="CheckIcon"
              />
            </b-button>

          </b-col>
          <b-col cols="auto">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="cursor-pointer"
              variant="outline-danger"
              @click="reprovarTroca(data.item)"
            >
              <feather-icon
                icon="XIcon"
              />
            </b-button>
          </b-col>
          <b-col cols="auto">
            <feather-icon
              variant="primary"
              icon="EyeIcon"
              class="cursor-pointer"
              @click="logTroca(data.item)"
            />
          </b-col>
        </b-row>
      </template>
      <template #cell(usuario)="data">
        {{ fisrtLastName(data.item.usuario) }}
      </template>

    </b-table>

    <b-modal
      ref="openModalTroca"

      title="Motivo da Troca"
      ok-only
      no-close-on-backdrop
      @ok="enviarStatusTrocaAprovado"
    >
      <span>Informe o motivo: </span>
      <b-form-input v-model="motivoTroca" />
    </b-modal>

    <b-modal
      ref="openModalTrocaNegado"

      title="Motivo Troca"
      ok-only
      no-close-on-backdrop
      @ok="enviarStatusTrocaReprovado"
    >
      <span>Informe o motivo: </span>
      <b-form-input v-model="motivoTroca" />
    </b-modal>

    <!-- Modal do log de anexos -->
    <b-modal
      ref="viewDataLog"
      title="Log de Troca Executivo"
      ok-only
    >
      <app-timeline>
        <app-timeline-item
          v-for="item in rendlogTroca"
          :key="item.id"
          :variant="item.status === 'Concluido' ? 'success' : item.status === 'Analise'? 'warning' : item.status === 'Reprovado' ? 'danger' : 'info'"
        >
          <div>
            <h6>
              {{ item.troca_executivo_id }}
            </h6>
            <b-list-group
              flush
              class="mt-1"
            >
              <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span><strong>Status: </strong>{{ item.status }}</span>
              </b-list-group-item>
              <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span><strong>Descrição: </strong>{{ item.descricao }}</span>
              </b-list-group-item>
              <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span><strong>Data: </strong>{{ item.created_at }}</span>
              </b-list-group-item>
              <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span><strong>Atualização: </strong>{{ item.updated_at }}</span>
              </b-list-group-item>

              <br>
            </b-list-group>

          </div>
        </app-timeline-item>
      </app-timeline>
    </b-modal>

    <!-- Modal do log de anexos -->

  </div>
</template>

<script>
import {
  BCol, BRow, BTable, BModal, BFormCheckbox, BListGroup, BListGroupItem, BFormInput, BButton,

} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import axios from '@/../axios-auth'

export default {
  components: {
    BCol,
    BFormCheckbox,
    BModal,
    BRow,
    BTable,
    BListGroup,
    BListGroupItem,
    AppTimeline,
    AppTimelineItem,
    BFormInput,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    updateTable: {
      type: Function,
      required: true,
      default: () => {},
    },

    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: 'acordo_id', label: 'ACORDO ID', class: 'text-center' },
        { key: 'cliente_id', label: 'CLIENTE ID', class: 'text-center' },
        { key: 'created_at', label: 'DATA DA SOLICITAÇÃO', class: 'text-center' },
        { key: 'usuario', label: 'SOLICITANTE', class: 'text-center' },
        { key: 'executivo_atual', label: 'EXECUTIVO ATUAL', class: 'text-center' },
        { key: 'executivo_novo', label: 'EXECUTIVO NOVO', class: 'text-center' },
        { key: 'acoes', class: 'text-center' },
      ],
      trocaID: null,
      rendlogTroca: {
        troca_executivo_id: '',
        status: '',
        descricao: '',
        created_at: '',
        updated_at: '',
      },
      motivoTroca: '',
    }
  },
  methods: {
    clearModal() {
      this.trocaID = null
      this.motivoTroca = ''
    },
    aprovarTroca(item) {
      this.trocaID = item.id
      this.$refs.openModalTroca.show()
    },
    enviarStatusTrocaAprovado() {
      axios.put(`api/v1/troca_executivo/update/status/${this.trocaID}`, {
        status: 'Aprovado',
        descricao: this.motivoTroca.toUpperCase(),

      }, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          Accept: 'application/json',
        },
      }).then(async () => {
        await this.updateTable()
        this.$swal({
          icon: 'success',
          text: 'Troca aprovada',
          timer: 1500,
          showConfirmButton: false,
        })
          .catch(async () => {
            this.$swal({
              icon: 'XIcon',
              text: 'Algo deu errado',
              timer: 1500,
              showConfirmButton: false,
            })
            await this.updateTable()
          })
      })
    },
    reprovarTroca(item) {
      this.trocaID = item.id
      this.$refs.openModalTrocaNegado.show()
    },
    enviarStatusTrocaReprovado() {
      axios.put(`api/v1/troca_executivo/update/status/${this.trocaID}`, {
        status: 'Reprovado',
        descricao: this.motivoTroca.toUpperCase(),

      }, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          Accept: 'application/json',
        },
      }).then(async () => {
        await this.updateTable()
        this.$swal({
          icon: 'success',
          text: 'Troca reprovada',
          timer: 1500,
          showConfirmButton: false,
        })
          .catch(async () => {
            this.$swal({
              icon: 'XIcon',
              text: 'Algo deu errado',
              timer: 1500,
              showConfirmButton: false,
            })
            await this.updateTable()
          })
      })
    },
    logTroca(item) {
      axios.get(`/api/v1/troca_executivo/listar/log/${item.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }).then(res => {
        const data = res.data.dados
        this.rendlogTroca = data.map(logItem => ({
          troca_executivo_id: logItem.troca_executivo_id,
          status: logItem.status,
          descricao: logItem.descricao,
          created_at: this.dataHora(logItem.created_at, true),
          updated_at: this.dataHora(logItem.updated_at, true),
        }))
      })

      this.$refs.viewDataLog.show()
    },
  },
}
</script>
