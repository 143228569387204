<template>
  <div>
    <b-card
      class="card-congratulation-medal"
    >
      <h5>Parabéns 🎉 {{ name }}!</h5>
      <b-card-text class="font-small-5">
        Seu Faturamento mensal
      </b-card-text>
      <h3 class="mb-75 mt-2 pt-50">
        <b-link>R$: {{ valorBr(faturamentoMensal) }}</b-link>
      </h3>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        v-b-modal.modal-acordo
        variant="primary"
      >
        Ver Acordos
      </b-button>
      <b-img
        :src="require('@/assets/images/illustration/badge.svg')"
        class="congratulation-medal"
        alt="Medal Pic"
      />
    </b-card>
    <b-modal
      id="modal-acordo"
      hide-footer
      size="xl"
      title="Meus Acordos"
    >
      <b-row
        align-h="between"
        class="my-1"
      >
        <b-col
          md="1"
        >
          <b-form-select
            v-model="perPage"
            size="sm"
            :options="[10, 15, 20]"
          />
        </b-col>
        <b-col
          md="10"
        >
          <b-form-input
            v-model="filter"
            type="search"
            placeholder="Pesquisar"
            size="sm"
          />
        </b-col>
      </b-row>
      <b-table
        small
        striped
        bordered
        responsive
        :filter="filter"
        :per-page="perPage"
        :current-page="currentPage"
        :fields="fieldsAcordos"
        :items="acordos"
        @filtered="onFiltered"
      >
        <template #cell(opcoes)="data">
          <b-row align-h="center">
            <b-col cols="auto">
              <feather-icon
                icon="EyeIcon"
                class="color-icon"
                @click="infoAcordo(data.item)"
              />
            </b-col>
            <b-col cols="auto">
              <feather-icon
                icon="DollarSignIcon"
                class="color-icon"
                @click="setCliente(data.item)"
              />
            </b-col>
          </b-row>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        align="center"
        size="sm"
      />

    </b-modal>
    <b-modal
      ref="modalInfo"
      :title="`Detalhes do Acordo ${nAcordo}`"
      no-close-on-backdrop
      hide-footer
      size="xl"
    >
      <InfoModalAcordo
        :id-acordo="idAcordo"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BLink,
  BButton,
  BImg,
  BCardText,
  BModal,
  BTable,
  BPagination,
  BFormInput,
  BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import axios from '@/../axios-auth'
import InfoModalAcordo from './ModalInfoAcordo.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
    BImg,
    BButton,
    BCardText,
    BModal,
    BTable,
    BPagination,
    BFormInput,
    BFormSelect,
    InfoModalAcordo,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      acordos: [],
      fieldsAcordos: [
        { key: 'numero_acordo', label: 'número acordo' },
        'status',
        'cliente',
        'credor',
        { key: 'desconto', class: 'text-right' },
        { key: 'honorarios', label: 'Hon. Variável', class: 'text-right' },
        { key: 'comissao', label: 'Hon. Fixo', class: 'text-right' },
        { key: 'quantidade_parcelas', label: 'qtd parcelas', class: 'text-center' },
        { key: 'valor_nominal', label: 'valor', class: 'text-right' },
        'dt_atualizacao',
        'opcoes',
      ],
      saleToday: '48k',
      executivo_cobranca: 0,
      name: localStorage.getItem('userName'),
      acordos_liquidados: 0,
      filter: '',
      idAcordo: '',
      nAcordo: '',
      faturamentoMensal: 0,
    }
  },
  mounted() {
    this.executivo_cobranca = localStorage.getItem('executivo_id')
    // this.stats_acordos()
    this.listAcordos()
    this.faturamentoMensalExecutivo()
  },
  methods: {
    kFormatter,
    async infoAcordo(item) {
      this.idAcordo = item.id
      this.nAcordo = item.numero_acordo

      await this.$refs.modalInfo.show()
    },
    async setCliente(item) {
      this.$router.push({ name: 'negociacao-cliente', params: { id: item.cliente_id } })
    },
    stats_acordos() {
      axios
        // eslint-disable-next-line camelcase
        .get(`api/v1/executivo/acordos/mes/${this.executivo_cobranca}`, {
          headers: {
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
            accept: 'application/json',
          },
        }).then(async res => {
          const { dados } = res.data
          const somaLiquidado = dados.map(item => {
            let valorReturn = 0
            if (item.status === 'Liquidado') {
              valorReturn = parseFloat(item.honorarios) || parseFloat(item.comissao !== null ? item.comissao : 0)
            }
            return valorReturn
          }).reduce((prev, curr) => prev + curr, 0)
          this.acordos_liquidados = somaLiquidado
        })
    },
    async listAcordos() {
      await axios.get(`api/v1/executivo/acordos/mes/${this.executivo_cobranca}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(dt => {
          this.acordos.push({
            id: dt.id,
            cliente: dt.nome_cliente,
            credor: dt.nome_credor,
            numero_acordo: dt.numero_acordo,
            status: dt.status,
            valor_nominal: this.valorBr(parseFloat(dt.valor_nominal), true),
            cliente_id: dt.cliente_id,
            desconto: this.valorBr(parseFloat(dt.desconto), true),
            honorarios: this.valorBr(parseFloat(dt.honorarios), true),
            quantidade_parcelas: dt.quantidade_parcelas,
            honorarios_porcento: dt.honorarios_porcento,
            dt_atualizacao: this.dataHora(dt.updated_at, true),
            comissao: dt.comissao !== null ? this.valorBr(parseFloat(dt.comissao), true) : 'R$ 0,00',
          })
        })
      })
      this.totalRows = this.acordos.length
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async faturamentoMensalExecutivo() {
      const date = new Date()
      const fim = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().slice(0, 10)
      const inicio = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().slice(0, 10)
      const body = {
        credores_id: [],
        data_inicio: inicio,
        data_final: fim,
      }
      await axios.post('api/v1/relatorios/backoffice/prestacao_contas', body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          let liquidado = 0
          const executivoId = localStorage.getItem('executivo_id')
          res.data.dados.map(item => {
            if (item.executivo_id === parseFloat(executivoId)) {
              if (item.remuneracao_voz) {
                liquidado += parseFloat(item.remuneracao_voz)
              }
              this.faturamentoMensal = liquidado
            }
          })
          this.$emit('faturamentoMensal', this.faturamentoMensal)
        })
        .catch()
    },
  },
}
</script>
