<template>
  <div>
    <b-row>
      <b-col md="2">
        <label>Filtro por Status</label>
        <v-select
          v-model="statusFilter"
          :options="statusOptions"
          class="mb-1"
          multiple
        />
      </b-col>
    </b-row>
    <b-table
      striped
      :items="listFiltered"
      :fields="fields"
    >
      <template #cell(telefone)="data">
        {{ data.item.telefone ? phoneLabel(data.item.telefone) : '' }}
      </template>
      <template #cell(cpf_cnpj)="data">
        {{ data.item.cpf_cnpj ? cpfCNPJ(data.item.cpf_cnpj) : '' }}
      </template>
      <template #cell(status)="data">
        <b-badge :variant="data.item.status === 'PENDENTE' ? 'warning' : 'success'">
          {{ data.item.status }}
        </b-badge>
      </template>
      <template #cell(created_at)="data">
        {{ data.item.created_at ? reverseDate(data.item.created_at) : '' }}
      </template>
      <template #cell(acoes)="data">
        <b-row align-h="center">
          <b-col cols="auto">
            <b-button
              v-if="data.item.status !== 'CONCLUIDO'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-success"
              @click="updateStatus(data.item.id)"
            >
              <feather-icon
                icon="CheckIcon"
              />
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="listFiltered.length"
      per-page="10"
      align="center"
      size="sm"
    />
  </div>
</template>

<script>
import { BTable, BRow, BCol, BButton, BBadge, BPagination } from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import axios from '@/../axios-auth'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    vSelect,
    BButton,
    BBadge,
    BPagination,
  },
  directives: {
    Ripple,
  },
  props: {
    solicitarligacao: {
      type: Array,
      default: () => {},
    },
    updateTable: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      fields: [
        { key: 'nome', label: 'NOME', class: 'text-center' },
        { key: 'cpf_cnpj', label: 'CPF', class: 'text-center' },
        { key: 'telefone', label: 'TELEFONE', class: 'text-center' },
        { key: 'status', label: 'STATUS', class: 'text-center' },
        { key: 'created_at', label: 'DATA/HORA', class: 'text-center' },
        { key: 'acoes', label: 'AÇÕES', class: 'text-center' },
      ],
      statusFilter: [],
      currentPage: 1,
    }
  },
  computed: {
    statusOptions() {
      const items = this.solicitarligacao
      const status = []
      items.map(item => {
        status.push(item.status)
      })
      const uniqueStatus = [...new Set(status)]
      return uniqueStatus
    },
    listFiltered() {
      const items = this.solicitarligacao
      const sf = this.statusFilter
      let list = items

      if (sf.length > 0) {
        list = []
        // Filtro por status
        let newFilterStatus = []
        const filterListStatus = []
        list.length > 0 ? (newFilterStatus = list) : (newFilterStatus = items)
        for (let i = 0; i <= sf.length - 1; i++) {
          newFilterStatus.map(item => {
            if (item.status === sf[i]) {
              filterListStatus.push(item)
            }
          })
          list = filterListStatus
        }
      }
      this.totalRows = list.length
      return list
    },
  },
  methods: {
    updateStatus(id) {
      const body = {
        status: 'CONCLUIDO',
      }
      axios.put(`api/v1/solicitar_ligacao/update/${id}`, body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.token),
          Accept: 'application/json',
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notificação',
              icon: 'BellIcon',
              text: 'Concluido com sucesso!',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          })
          this.updateTable()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notificação',
              icon: 'BellIcon',
              text: 'Ops, algo deu errado',
              variant: 'danger',
            },
          },
          {
            position: 'top-center',
          })
          this.updateTable()
        })
    },
    reverseDate(date) {
      const dateToReverse = date.substr(0, 10)
      const dateHours = date.substr(11, 15)
      const reversedDate = dateToReverse.split('-').reverse().join('/')
      return `${reversedDate} ${dateHours}`
    },
  },
}
</script>
