<template>
  <div>
    <b-row>
      <b-col>
        <b-card class="d-flex justify-content-center mr-2">
          <b-row class="mr-2">
            <b-col md="6">
              <b-row class="mt-1">
                <div
                  class="d-flex align-items-center mr-2 expandir"
                  @click="iconFilter(1)"
                >
                  <b-avatar
                    variant="light-success"
                    rounded
                  >
                    <feather-icon
                      icon="FileTextIcon"
                      size="20"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      {{ totalAcordos.length }}
                    </h5>
                    <small>Total</small>
                  </div>
                </div>

                <div
                  class="d-flex align-items-center mr-2 expandir"
                  @click="iconFilter(2)"
                >
                  <b-avatar
                    variant="light-danger"
                    rounded
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="20"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      {{ quantityAgreeCancell.length }}
                    </h5>
                    <small>Cancelados</small>
                  </div>
                </div>

                <div
                  class="d-flex align-items-center mr-2 expandir"
                  @click="iconFilter(3)"
                >
                  <b-avatar
                    variant="light-warning"
                    rounded
                  >
                    <feather-icon
                      icon="ClockIcon"
                      size="20"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      {{ quantityAgreeWaiting.length }}
                    </h5>
                    <small>Aguardando</small>
                  </div>
                </div>

                <div
                  class="d-flex align-items-center mr-2 expandir"
                  @click="iconFilter(4)"
                >
                  <b-avatar
                    variant="light-success"
                    rounded
                  >
                    <feather-icon
                      icon="DollarSignIcon"
                      size="20"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      {{ quantityAgreeLiquidated.length }}
                    </h5>
                    <small>Liquidados</small>
                  </div>
                </div>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        >
          <b-row align-h="between">
            <b-col lg="1">
              <b-form-select
                v-model="perPage"
                :options="[10, 15, 20]"
                size="sm"
              />
            </b-col>
            <!--Filtro criado por status-->
            <!-- <b-col
              md="2"
              class="select-size-sm"
            >
              <label>Filtro por Periodo</label>
              <v-select
                v-model="filterPerDate"
                :options="dateFilter"
                multiple
              />
            </b-col>
            <br> -->
            <!--Filtro criado por status-->

            <b-col
              lg="8"
              class="d-flex justify-content-end"
            >
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="Pesquisar"
                size="sm"
              />
            </b-col>
            <b-col
              cols="auto"
            >
              <b-button
                variant="outline-warning"
                @click="exportXlsx"
              >
                <feather-icon
                  icon="DownloadIcon"
                  class="mr-50"
                />
                Exportar excel
              </b-button>
            </b-col>

          </b-row>
        </b-col>
        <b-col cols="12">
          <b-table
            ref="selectableTable"
            responsive
            bordered
            hover
            small
            striped
            :filter="filter"
            :current-page="currentPage"
            :per-page="perPage"
            :fields="fields"
            :items="itemsAcordos"
            :update-table="setFilter"
            class="text-center"
            @filtered="onFiltered"
          >
            <template #cell(executivo_cobranca_id)="data">
              {{ data.item.executivo }}
            </template>
            <template #cell(status)="data">
              <b-badge
                :variant="resolveVariantStatus(data.item.status)"
              >
                {{ data.item.status }}
              </b-badge>
            </template>
            <template #cell(created_at)="data">
              {{ data.item.created_at ? dataHora(data.item.created_at, true) : '' }}
            </template>
            <template #cell(opcoes)="data">
              <b-row align-h="center">
                <b-col cols="auto">
                  <feather-icon
                    icon="EyeIcon"
                    class="color-icon"
                    @click="infoAcordo(data.item)"
                  />
                </b-col>
                <b-col cols="auto">
                  <feather-icon
                    icon="DollarSignIcon"
                    class="color-icon"
                    @click="setCliente(data.item)"
                  />
                </b-col>
              </b-row>
            </template>
          </b-table>
        </b-col>
        <b-col>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            size="sm"
            align="center"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      ref="infoAcordo"
      title="Detalhes do Acordo"
      :hide-footer="!podeCancelar"
      size="xl"
      ok-only
      no-close-on-backdrop
      ok-title="Cancelar Acordo"
      ok-variant="danger"
      @ok="quebrarAcordo"
      @hide="podeCancelar = false"
    >
      <modal-acordo
        :info="acordo"
        :cancelar="podeCancelar"
        :update-table="updateTable"
      />
    </b-modal>
  </div>
</template>

<script>

import {
  BFormSelect,
  BPagination,
  BFormInput,
  BCol,
  BAvatar,
  BBadge,
  BRow,
  BButton,
  BCard,
  BTable,
  // BLink,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'

// eslint-disable-next-line no-unused-vars
import { saveAs } from 'file-saver'
import axios from '@/../axios-auth'
import modalAcordo from './AcordoModal.vue'

const ExcelJS = require('exceljs')

export default {
  components: {
    BButton,
    BFormSelect,
    BBadge,
    BAvatar,
    BPagination,
    BFormInput,
    BCol,
    BRow,
    BCard,
    // vSelect,
    BTable,
    modalAcordo,
    // BLink,
  },
  props: {
    acordos: {
      type: Array,
      required: true,
    },
    updateTable: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      user: '',
      userData: '',
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      filter: null,
      cliente: [],
      fields: [
        {
          key: 'cliente_id', label: 'Cliente ID',
        },
        {
          key: 'created_at', label: 'Data acordo',
        },
        {
          key: 'numero_acordo', label: 'Numero acordo',
        },
        {
          key: 'credor', label: 'Credor', sortable: true,
        },
        {
          key: 'cliente', class: 'text-left',
        },
        {
          key: 'executivo_cobranca_id', label: 'executivo', class: 'text-left',
        },
        {
          key: 'valor_nominal', label: 'Valor acordo',
        },
        {
          key: 'status', label: 'status',
        },
        {
          key: 'quantidade_parcelas', label: 'Qnt. Parcelas', class: 'text-center',
        },
        'opcoes',
      ],
      itemsAcordos: [],
      acordo: null,
      podeCancelar: false,
      quantityAgreeCancell: '',
      quantityAgreeLiquidated: '',
      quantityAgreeWaiting: '',
      acordosFilter: [],
      totalAcordos: [],
    }
  },

  watch: {
    filter(n) {
      if (n.length > 0) {
        const acordos = this.itemsAcordos
        const list = []
        const cancelledWatch = []
        const waitingWatch = []
        const quantityAgreeLiquidatedWatch = []
        acordos.map(item => {
          if (item.credor.includes(n.toUpperCase().trim())) {
            if (item.status) {
              list.push(item)
            }
          }
        })
        list.filter(item => {
          item.status === 'Cancelado' ? cancelledWatch.push(item.status) : null
        })
        list.filter(item => {
          item.status === 'Aguardando' ? waitingWatch.push(item.status) : null
        })
        list.filter(item => {
          item.status === 'Liquidado' ? quantityAgreeLiquidatedWatch.push(item.status) : null
        })
        this.totalAcordos = list
        this.quantityAgreeCancell = cancelledWatch
        this.quantityAgreeWaiting = waitingWatch
        this.quantityAgreeLiquidated = quantityAgreeLiquidatedWatch
        this.totalRows = list.length
      } else {
        const acordos = this.itemsAcordos
        const cancelled = []
        const quantityAgreeLiquidated = []
        const waiting = []
        // this.itemsAcordos = acordos
        this.totalRows = acordos.length
        this.totalAcordos = acordos

        acordos.filter(item => {
          item.status === 'Cancelado' ? cancelled.push(item.status) : null
        })
        acordos.filter(item => {
          item.status === 'Aguardando' ? waiting.push(item.status) : null
        })
        acordos.filter(item => {
          item.status === 'Liquidado' ? quantityAgreeLiquidated.push(item.status) : null
        })

        this.quantityAgreeCancell = cancelled
        this.quantityAgreeLiquidated = quantityAgreeLiquidated
        this.quantityAgreeWaiting = waiting
      }
    },
    deep: true,
  },
  async created() {
    await this.setItems(this.acordos)
    // this.user = localStorage.getItem('userName')
    // this.totalRows = this.acordos.length
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async quebrarAcordo() {
      this.$swal({
        title: 'Você tem certeza?',
        text: 'Essa ação é incapaz de ser desfeita!!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, confirmo!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.get(`api/v1/acordo/quebra_acordo/${this.acordo.id}`, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.token),
            },
          }).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Ação concluída!',
              text: 'Lembrete: Essa ação não pode ser desfeita.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.updateTable()
          })
        }
      })
    },

    async setCliente(item) {
      this.$router.push({ name: 'negociacao-cliente', params: { id: item.cliente_id } })
    },

    async infoAcordo(item) {
      await axios.get(`api/v1/acordo/operacoes/${item.id}`, {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      })
        .then(res => {
          this.acordo = res.data.dados
        })

      let status = []
      this.acordos.map(parcela => {
        status.push(parcela.status)
      })
      status = [...new Set(status)]
      if (status.length === 1) {
        if (status[0] === 'avaible') {
          this.podeCancelar = false
        } else {
          this.podeCancelar = true
        }
      } if (status.length > 1) {
        this.podeCancelar = true
      } if (status.length === 0) {
        this.podeCancelar = false
      }
      await this.$refs.infoAcordo.show()
    },

    setItems(items) {
      this.userData = localStorage.getItem('funcao')
      this.user = localStorage.getItem('userName')

      const cancelled = []
      const quantityAgreeLiquidated = []
      const waiting = []

      const acordos = []

      if (this.userData !== 'Executivo de Cobrança') {
        items.map(item => {
          acordos.push(item)
        })
      }
      items.map(item => {
        this.user === item.executivo ? acordos.unshift(item) : null
      })
      acordos.map(item => {
        // const data = item.created_at
        // eslint-disable-next-line no-self-assign
        item.cliente = item.cliente
        item.cliente_id
        item.id
        item.created_at
        item.quantidade_parcelas === '1' ? item.quantidade_parcelas = 'AVISTA' : item.quantidade_parcelas
        // item.executivo_cobranca.cpf = this.cpfLabel(item.executivo_cobranca.cpf)

        item.desconto = this.valorBr(parseFloat(item.desconto), true)
        item.honorarios = this.valorBr(parseFloat(item.honorarios), true)
        item.juros = this.valorBr(parseFloat(item.juros), true)
        item.multas = this.valorBr(parseFloat(item.multas), true)
        item.valor_entrada = this.valorBr(parseFloat(item.valor_entrada), true)
        item.valor_nominal = this.valorBr(parseFloat(item.valor_nominal), true)
        item.valor_parcela = this.valorBr(parseFloat(item.valor_parcela), true)

        item.data_cancelou ? item.data_cancelou = this.formatTimezone(item.data_cancelou) : ''
        item.usuario_cancelou_id ? item.usuario_cancelou_id : item.usuario_cancelou_id = ''

        // item.parcelas.map(parcela => {
        //   parcela.id
        //   parcela.valor_baixado ? parcela.valor_baixado = parseFloat(parcela.valor_baixado) : parcela.valor_baixado = 'PENDENTE'
        //   parcela.valor_honorario = parseFloat(parcela.valor_honorario) // Antes estava valor_nominal
        //   parcela.valor_juros = parseFloat(parcela.valor_juros)
        //   parcela.valor_multa = parseFloat(parcela.valor_multa)
        //   parcela.valor_nominal = parseFloat(parcela.valor_nominal)
        //   // this.verifyVencimentoParcela(parcela.data_vencimento) ? parcela.status = 'expired' : 'avaible'
        // })
      })
      this.itemsAcordos = acordos
      this.totalRows = acordos.length
      this.totalAcordos = acordos

      acordos.filter(item => {
        item.status === 'Cancelado' ? cancelled.push(item.status) : null
      })
      acordos.filter(item => {
        item.status === 'Aguardando' ? waiting.push(item.status) : null
      })
      acordos.filter(item => {
        item.status === 'Liquidado' ? quantityAgreeLiquidated.push(item.status) : null
      })

      this.quantityAgreeCancell = cancelled
      this.quantityAgreeLiquidated = quantityAgreeLiquidated
      this.quantityAgreeWaiting = waiting
    },
    async setFilter() {
      this.$refs.filtro.setFilter()
    },

    async exportXlsx() {
      const file = await this.getXlsx()
      if (file) {
        saveAs(file)
      }
    },
    async getXlsx() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Acordos')
      const itemsTable = this.itemsAcordos

      worksheet.columns = [
        { header: 'Cliente ID' },
        { header: 'Data acordo' },
        { header: 'Numero acordo' },
        { header: 'Credor' },
        { header: 'Cliente' },
        { header: 'Executivo' },
        { header: 'Valor acordo' },
        { header: 'Status' },
        { header: 'Qtd. parcelas' },
      ]

      itemsTable.map(itemRow => {
        const values = {
          cliente_id: '',
          created_at: '',
          numero_acordo: '',
          credor: '',
          cliente: '',
          executivo: '',
          valor_nominal: '',
          status: '',
          quantidade_parcelas: '',
        }

        values.cliente_id = itemRow.cliente_id
        values.created_at = itemRow.created_at ? this.dataHora(itemRow.created_at, true) : ''
        values.numero_acordo = itemRow.numero_acordo
        values.credor = itemRow.credor
        values.cliente = itemRow.cliente
        values.executivo = itemRow.executivo
        values.valor_nominal = itemRow.valor_nominal
        values.status = itemRow.status
        values.quantidade_parcelas = itemRow.quantidade_parcelas

        worksheet.addRow([
          values.cliente_id,
          values.created_at,
          values.numero_acordo,
          values.credor,
          values.cliente,
          values.executivo,
          values.valor_nominal,
          values.status,
          values.quantidade_parcelas,
        ])
      })
      worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
        row.eachCell(cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })

      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: false }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], 'Acordos', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },
    resolveVariantStatus(itemStatus) {
      if (itemStatus) {
        const letter = itemStatus.toLowerCase()
        const variants = {
          liquidado: 'success',
          cancelado: 'danger',
          aguardando: 'warning',
        }
        if (variants[letter]) {
          return variants[letter]
        }
      }
      return 'info'
    },
    iconFilter(key) {
      switch (key) {
        case 1:
          this.itemsAcordos = this.totalAcordos
          break
        case 2: {
          this.itemsAcordos = this.totalAcordos
          const sts = this.itemsAcordos.filter(
            item => item.status === 'Cancelado',
          )
          this.itemsAcordos = sts
          break
        }
        case 3: {
          this.itemsAcordos = this.totalAcordos
          const sts = this.itemsAcordos.filter(
            item => item.status === 'Aguardando',
          )
          this.itemsAcordos = sts
          break
        }
        case 4: {
          this.itemsAcordos = this.totalAcordos
          const sts = this.itemsAcordos.filter(
            item => item.status === 'Liquidado',
          )
          this.itemsAcordos = sts
          break
        }
        default:
          break
      }
    },
  },
}
</script>
<style lang="scss">
  .expandir {
      transition: all 0.3s ease;
    }

    .expandir:hover {
      transform: scale(1.1);
      opacity: 0.8;
    }
</style>
