<template>
  <div>
    <b-tabs justified>
      <b-tab>
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>Acordo</span>
        </template>
        <b-row class="mb-1">
          <b-col>
            <label><strong>Nº Acordo</strong></label>
            <b-form-input
              v-model="acordo.numero_acordo"
              disabled
            />
          </b-col>
          <b-col>
            <label for=""><strong>Status</strong></label>
            <b-form-input
              v-model="acordo.status"
              disabled
            />
          </b-col>
          <b-col>
            <label for=""><strong>Valor Nominal</strong></label>
            <b-form-input
              v-model="acordo.valor_nominal"
              disabled
            />
          </b-col>
          <b-col>
            <label for=""><strong>Valor Entrada</strong></label>
            <b-form-input
              v-model="acordo.valor_entrada"
              disabled
            />
          </b-col>
          <b-col>
            <label for=""><strong>Qntº Parcelas</strong></label>
            <b-form-input
              v-model="acordo.quantidade_parcelas"
              disabled
            />
          </b-col>
        </b-row>

        <b-row class="mb-1">
          <b-col>
            <label><strong>Valor da Parcela</strong></label>
            <b-form-input
              v-model="acordo.valor_parcela"
              disabled
            />
          </b-col>
          <b-col>
            <label for=""><strong>{{ acordo.juros_porcento }}Juros</strong></label>
            <b-form-input
              v-model="acordo.juros"
              disabled
            />
          </b-col>

          <b-col>
            <label for=""><strong>{{ acordo.multas_porcento }}Multas</strong></label>
            <b-form-input
              v-model="acordo.multas"
              disabled
            />
          </b-col>

          <b-col>
            <label for=""><strong>{{ acordo.honorarios_porcento }}Hon. Variável</strong></label>
            <b-form-input
              v-model="acordo.honorarios"
              disabled
            />
          </b-col>
          <b-col>
            <label for=""><strong>{{ acordo.honorarios_porcento }}Hon. Fixo</strong></label>
            <b-form-input
              v-model="acordo.comissao"
              disabled
            />
          </b-col>
        </b-row>
        <b-row
          v-if="!cancelar"
          class="mb-1"
        >
          <b-col>
            <label for=""><strong>Data Cancelamento</strong></label>
            <b-form-input
              v-model="acordo.data_cancelou"
              disabled
            />
          </b-col>
          <b-col>
            <label for=""><strong>Desc. Cancelamento</strong></label>
            <b-form-input
              v-model="acordo.descricao_cancelamento"
              disabled
            />
          </b-col>
          <b-col>
            <label for=""><strong>Usuário Cancelou</strong></label>
            <b-form-input
              v-model="acordo.usuario_cancelou"
              disabled
            />
          </b-col>
        </b-row>
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="ClockIcon" />
          <span>Operações</span>
        </template>
        <b-table
          responsive
          bordered
          striped
          hover
          :fields="fieldsOperacoes"
          :items="operacoes"
        >
          <template #cell(data_vencimento)="data">
            {{ dataHora(data.item.data_vencimento, true) }}
          </template>
          <template #cell(valor_atualizado)="data">
            {{ valorBr(data.item.valor_atualizado, true) }}
          </template>
        </b-table>
      </b-tab>

      <b-tab v-if="cancelar">
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>Parcelas</span>
        </template>
        <b-table
          responsive
          striped
          :fields="fieldsParcelas"
          :items="parcelas"
        >
          <template #cell(data_vencimento)="data">
            {{ americaDate(data.item.data_vencimento) }}
          </template>
          <template #cell(valor_nominal)="data">
            {{ valorBr(parseFloat(data.item.valor_nominal), true) }}
          </template>
          <template #cell(valor_comissao)="data">
            {{ valorBr(parseFloat(data.item.valor_comissao), true) }}
          </template>
        </b-table>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BFormInput,
  BTabs,
  BTab,
  BTable,
} from 'bootstrap-vue'
import axios from '@/../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BTabs,
    BTab,
    BTable,
  },
  props: {
    idAcordo: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      acordo: {},
      fieldsParcelas: [
        { key: 'numero_parcela', label: 'parcela' },
        { key: 'forma_pagamento' },
        { key: 'data_baixa', label: 'dt. baixa' },
        { key: 'valor_baixa', label: 'vlr. baixa' },
        { key: 'valor_comissao', label: 'Hon. Fixo', class: 'text-right' },
        { key: 'data_vencimento', label: 'vencimento' },
        { key: 'valor_nominal', label: 'valor' },
      ],
      parcelas: [],
      cancelar: true,
      fieldsOperacoes: [
        { key: 'numero_operacao', label: 'operacao' },
        'aluno',
        { key: 'data_vencimento', label: 'vencimento' },
        { key: 'valor_atualizado', label: 'valor', class: 'text-right' },
      ],
      operacoes: [],
    }
  },
  async created() {
    await this.getAcordo()
  },
  methods: {
    async getAcordo() {
      await axios.get(`api/v1/acordo/edit/${this.idAcordo}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }).then(async res => {
        this.operacoes = res.data.dados.operacoes
        this.parcelas = res.data.dados.acordo.parcelas

        this.acordo = {
          numero_acordo: res.data.dados.acordo.numero_acordo,
          status: res.data.dados.acordo.status,
          valor_nominal: this.valorBr(parseFloat(res.data.dados.acordo.valor_nominal), true),
          valor_entrada: this.valorBr(parseFloat(res.data.dados.acordo.valor_entrada), true),
          quantidade_parcelas: res.data.dados.acordo.quantidade_parcelas,
          valor_parcela: this.valorBr(parseFloat(res.data.dados.acordo.valor_parcela), true),
          juros: this.valorBr(parseFloat(res.data.dados.acordo.juros), true),
          multas: this.valorBr(parseFloat(res.data.dados.acordo.multas), true),
          honorarios: this.valorBr(parseFloat(res.data.dados.acordo.honorarios), true),
          comissao: res.data.dados.acordo.comissao !== null ? this.valorBr(parseFloat(res.data.dados.acordo.comissao), true) : 0,

          usuario_cancelou: res.data.dados.acordo.usuario_cancelou ? res.data.dados.acordo.usuario_cancelou.user.name : '',
          data_cancelou: res.data.dados.acordo.data_cancelou,
          descricao_cancelamento: res.data.dados.acordo.descricao_cancelamento,
        }

        if (this.acordo.status === 'Negociado') {
          this.cancelar = true
        } else {
          this.cancelar = false
        }
      })
    },
  },
}
</script>
