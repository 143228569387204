<template>
  <div>
    <b-col hidden>
      <DashMigracao />
    </b-col>
    <b-row class="match-height">
      <b-col md="4">
        <Medal
          @faturamentoMensal="faturamentoMensal"
        />
      </b-col>
      <b-col md="8">
        <Totals :select-card="loadCard" />
      </b-col>
    </b-row>
    <section
      v-if="dadosDash"
    >
      <b-row>
        <b-col md="4">
          <Meta
            :title="'Minha Meta'"
            :dados="metaExecutivo"
          />
        </b-col>
        <b-col md="4">
          <Meta
            :title="`Carteira: ${metaUltimaCarteira.description}`"
            :dados="metaUltimaCarteira"
          />
        </b-col>
        <b-col md="4">
          <Meta
            :title="'Meta Geral'"
            :dados="metaGeral"
          />
        </b-col>
      </b-row>
    </section>
    <b-row>
      <b-col v-if="card === 1">
        <InvoiceList />
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="card === 0">
        <FaturamentoExecutivo />
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import axios from '@/../axios-auth'

// import firebase from '@/../firebase'
import Medal from './components/Medal.vue'
import Meta from './components/Meta.vue'
import Totals from './components/Totals.vue'
import InvoiceList from './components/InvoiceList.vue'
import DashMigracao from '../supervisor/components/DashMigracao.vue'
import FaturamentoExecutivo from './components/FaturamentoExecutivo.vue'

export default {
  components: {
    Medal,
    BRow,
    BCol,
    Meta,
    Totals,
    InvoiceList,
    DashMigracao,
    FaturamentoExecutivo,
  },
  data() {
    return {
      dadosDash: true,
      executivo_cobranca: '',
      metaExecutivo: {
        series: [],
        completed: 0,
        inProgress: 0,
        description: '',
      },
      metaUltimaCarteira: {
        series: [],
        completed: 0,
        inProgress: 200000,
        description: '',
      },
      metaPenultimaCarteira: {
        series: [],
        completed: 0,
        inProgress: 200000,
        description: '',
      },
      metaGeral: {},
      ultimaCarteira: 0,
      card: '',
      metaMensal: 0,
    }
  },
  async created() {
    await this.getMetaExecutivo()
    await this.findCarteiras()
  },
  methods: {
    execute(url) {
      return axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
    },
    loadCard(data) {
      if (this.card === data) {
        this.card = null
      }
      this.card = data
    },
    async findCarteiras() {
      await this.execute('api/v1/carteira').then(async res => {
        const { dados } = res.data
        const ids = []

        dados.map(dt => {
          ids.push(dt.id)
        })

        this.ultimaCarteira = Math.max(...ids)

        await this.execute(`api/v1/carteira/get/${this.ultimaCarteira}`).then(async response => {
          this.metaUltimaCarteira.description = response.data.dados.description

          this.metaUltimaCarteira.inProgress = parseFloat(response.data.dados.meta)
          this.metaUltimaCarteira.completed = parseFloat(response.data.dados.liquidado)
          this.metaUltimaCarteira.series.push(parseFloat(response.data.dados.progress) % 1 === 0 ? parseFloat(response.data.dados.progress) : parseFloat(response.data.dados.progress).toFixed(2))
        })

        // await this.execute(`api/v1/carteira/get/${this.ultimaCarteira - 1}`).then(async result => {
        //   this.metaPenultimaCarteira.description = result.data.dados.description

        //   this.metaPenultimaCarteira.inProgress = parseFloat(result.data.dados.meta) ? parseFloat(result.data.dados.meta) : 0
        //   this.metaPenultimaCarteira.completed = parseFloat(result.data.dados.liquidado) ? parseFloat(result.data.dados.liquidado) : 0
        //   this.metaPenultimaCarteira.series.push(parseFloat(result.data.dados.progress) % 1 === 0 ? parseFloat(result.data.dados.progress) : parseFloat(result.data.dados.progress).toFixed(2))
        // })

        // this.metaGeral = {
        //   inProgress: this.metaPenultimaCarteira.inProgress ? this.metaPenultimaCarteira.inProgress : 0 + this.metaUltimaCarteira.inProgress,
        //   completed: this.metaUltimaCarteira.completed ? this.metaUltimaCarteira.completed : 0 + this.metaPenultimaCarteira.completed,
        //   series: [this.metaUltimaCarteira.series ? this.metaUltimaCarteira.series : 0 + this.metaPenultimaCarteira.series],
        // }
        this.metaGeral = {
          inProgress: this.metaUltimaCarteira.inProgress,
          completed: this.metaUltimaCarteira.completed,
          series: [this.metaUltimaCarteira.series],
        }
      })
    },
    faturamentoMensal(liquidado) {
      this.metaMensal = liquidado
      this.metaExecutivo.completed = liquidado
      if (this.metaExecutivo.inProgress !== 0) {
        this.metaExecutivo.series.push(((this.metaExecutivo.completed / this.metaExecutivo.inProgress) * 100).toFixed(2))
      } else {
        this.metaExecutivo.series.push(0.00)
      }
    },
    async getMetaExecutivo() {
      this.executivo_cobranca = localStorage.getItem('executivo_id')
      // this.metaExecutivo.inProgress = parseFloat(localStorage.getItem('meta'))
      await axios.get(`api/v1/meta_executivo/listar/${this.executivo_cobranca}`)
        .then(res => {
          const { dados } = res.data
          if (dados.length === 0) {
            this.metaExecutivo.inProgress = 0
          } else {
            this.metaExecutivo.inProgress = parseFloat(dados[0].valor)
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
