<template>
  <div>
    <b-col
      md="2"
      offset-md="4"
    >
      <b-alert
        variant="danger"
        show
      >
        <h4 class="alert-heading text-center">
          Anexos: {{ totalAnexo }}
        </h4>
      </b-alert>
    </b-col>
    <b-row class="mb-2">
      <b-col md="2">
        <label>Filtro por status</label>
        <v-select
          v-model="statusFilter"
          :options="optionsStatus"
          multiple
        />
      </b-col>
      <b-col md="2">
        <label>Filtro por credor</label>
        <v-select
          v-model="credoresFilter"
          :options="optionsCredor"
          multiple
        />
      </b-col>

      <b-col align-self="end">
        <b-button
          v-show="selected.length > 0"
          variant="outline-warning"
          :disabled="conditionals() === false || finishedStatus() === false"
          class="ml-2"
          @click="$refs.statusAnexo.show()"
        >
          {{
            conditionals() === false ? 'Status diferentes!' :
            finishedStatus() === false ? 'Já encontra-se concluido' : 'Tratar Anexo'
          }}
        </b-button>
      </b-col>
      <b-col align-self="end">
        <b-button
          variant="outline-primary"
          @click="exportFile"
        >
          Exportar em Excel
        </b-button>
      </b-col>
    </b-row>
    <b-table
      small
      striped
      responsive
      :fields="fields"
      :items="listFiltered"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #head(check)>
        <b-form-checkbox
          v-model="selectAll"
        />
      </template>

      <template
        #cell(check)="row"
      >
        <b-form-checkbox
          v-model="selected"
          :value="row.item"
        />
      </template>
      <template #cell(info_pendencia)="data">
        {{ resolveJsonInfoPendencia(data.item.info_pendencia) }}
      </template>

      <template #cell(created_at)="data">
        {{ formatTimezone(data.item.created_at) }}
      </template>
      <template #cell(updated_at)="data">
        {{ formatTimezone(data.item.updated_at) }}
      </template>

      <template
        #cell(acoes)="data"
      >
        <b-row align-h="center">
          <b-col cols="auto">
            <feather-icon
              variant="danger"
              icon="DollarSignIcon"
              class="cursor-pointer"
              @click="toChangeView(data.item)"
            />

          </b-col>
          <b-col cols="auto">
            <feather-icon
              variant="primary"
              icon="EyeIcon"
              class="cursor-pointer"
              @click="preview(data.item)"
            />
          </b-col>
          <b-col cols="auto">
            <feather-icon
              variant="primary"
              icon="InfoIcon"
              class="cursor-pointer"
              @click="logAnexos(data.item)"
            />
          </b-col>
        </b-row>
      </template>
      <template #cell(status)="data">
        <b-badge
          :variant="data.item.status === 'CONCLUÍDO' ? 'light-success' :
            data.item.status === 'EM ANÁLISE' ? 'light-warning' : 'light-info'
          "
        >
          {{ data.item.status }}
        </b-badge>
      </template>

    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      align="center"
    />

    <b-modal
      id="preview"
      ref="preview"
      hide-footer
      size="xl"
      title=""
    >
      <iframe
        id="myFrame"
        :src="urlPreview"
      />
    </b-modal>

    <b-modal
      ref="statusAnexo"

      title="Modificar status do anexo"
      ok-only
      no-close-on-backdrop
      @ok="updateStatusAnexo(statusSelected), sendAnexo(statusSelected)"
    >
      <span>Selecione o status</span>
      <v-select
        v-model="statusSelected"
        :options="statusOptions"
      />
    </b-modal>

    <!-- Modal do log de anexos -->
    <b-modal
      ref="viewDataLog"
      title="Log de Anexos"
      ok-only
    >
      <app-timeline>
        <app-timeline-item
          v-for="item in rend"
          :key="item.id"
          :variant="item.status === 'CONCLUIDO' ? 'success' : item.status === 'EM ANÁLISE'? 'warning' : item.status === 'RECEBIDO' ? 'secondary' : 'info'"
        >
          <div>
            <h6>
              {{ item.id }}
            </h6>

            <b-list-group
              flush
              class="mt-1"
            >
              <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span><strong>Status: </strong>{{ item.status }}</span>
              </b-list-group-item>
              <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span><strong>Data: </strong>{{ item.data_criação }}</span>
              </b-list-group-item>
              <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span><strong>Atualização: </strong>{{ item.data_atualização }}</span>
              </b-list-group-item>
              <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span><strong>Nome usuário: </strong>{{ item.nome_usuario }}</span>
              </b-list-group-item>

              <br>
            </b-list-group>

          </div>
        </app-timeline-item>
      </app-timeline>
    </b-modal>

    <!-- Modal do log de anexos -->

  </div>
</template>

<script>
import {
  BCol, BRow, BTable, BModal, BButton, BFormCheckbox, BBadge, BListGroup, BListGroupItem, BAlert, BPagination,

} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import vSelect from 'vue-select'
import { saveAs } from 'file-saver'
import ExcelJS from 'exceljs'
import axios from '@/../axios-auth'

export default {
  components: {
    BCol,
    BBadge,
    BButton,
    BFormCheckbox,
    BModal,
    BRow,
    BTable,
    BListGroup,
    BListGroupItem,
    AppTimeline,
    AppTimelineItem,
    vSelect,
    BAlert,
    BPagination,
  },

  props: {
    updateTable: {
      type: Function,
      required: true,
      default: () => {},
    },

    items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      // optionsStatus: [
      //   'REGISTRADO',
      //   'CONCLUÍDO',
      //   'EM ANÁLISE',
      // ],
      statusFilter: [],
      credoresFilter: [],
      statusSelected: '',
      statusOptions: ['EM ANÁLISE', 'CONCLUÍDO'],
      urlPreview: '',
      selected: [],
      selectAll: false,
      fields: [
        {
          key: 'check',
        },
        // {
        //   key: 'id', label: 'id', class: 'text-center',
        // },
        {
          key: 'cliente_id', label: 'ID cliente', class: 'text-center',
        },
        {
          key: 'info_pendencia', label: 'Operações', class: 'text-center',
        },
        // {
        //   key: 'user_id', label: 'ID usuário', class: 'text-center',
        // },
        // {
        //   key: 'numero_anexo', label: 'número anexo', class: 'text-center',
        // },
        {
          key: 'nome', label: 'nome arquivo', class: 'text-center',
        },
        {
          key: 'nome_cliente', label: 'nome cliente', class: 'text-center',
        },
        {
          key: 'nome_credor', label: 'nome credor', class: 'text-center',
        },
        {
          key: 'status', label: 'status', class: 'text-center',
        },
        {
          key: 'created_at', label: 'data anexo', class: 'text-center',
        },
        {
          key: 'updated_at', label: 'data atualização', class: 'text-center',
        },
        {
          key: 'acoes', class: 'text-center',
        },

      ],
      rend: {
        anexo_id: '',
        created_at: '',
        id: '',
        status: '',
        updated_at: '',
        user_id: '',
        usuario: '',
      },
      totalAnexo: null,
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
    }
  },

  computed: {
    optionsStatus() {
      const { items } = this
      const status = []

      items.map(item => {
        item.status ? status.push(item.status) : null
      })

      const itemsFiltered = [...new Set(status)]

      return itemsFiltered
    },

    optionsCredor() {
      const { items } = this
      const credores = []

      items.map(item => {
        item.nome_credor ? credores.push(item.nome_credor) : null
      })

      const credoresFiltered = [...new Set(credores)]
      return credoresFiltered
    },

    listFiltered() {
      const { items, statusFilter: status, credoresFilter: credor } = this
      let itemsList = items

      if (status.length > 0 || credor.length > 0) {
        itemsList = []

        for (let i = 0; i <= status.length - 1; i++) {
          // eslint-disable-next-line no-loop-func
          items.map(item => {
            if (item.status === status[i]) {
              itemsList.push(item)
            }
          })
        }

        for (let i = 0; i <= credor.length - 1; i++) {
          let newFilter = []
          itemsList.length > 0 ? (newFilter = itemsList) : (newFilter = items)
          const filterList = []
          newFilter.map(item => {
            if (item.nome_credor === credor[i]) {
              filterList.push(item)
            }
          })
          itemsList = filterList
        }
      }

      itemsList.sort((a, b) => {
        if (a.id < b.id) {
          return 1
        }
        if (a.id > b.id) {
          return -1
        }
        return 0
      })
      this.totalAnexo = itemsList.length
      this.totalRows = itemsList.length
      return itemsList
    },
  },

  watch: {
    selectAll(n) {
      n ? this.selecionarTodos() : this.limparSelecao()
    },
  },

  methods: {
    conditionals() {
      const status = []
      this.selected.map(item => {
        status.push(item.status)
      })
      const verify = [...new Set(status)]
      if (verify.length > 1) {
        return false
      }
      return true
    },

    finishedStatus() {
      const finished = []
      this.selected.map(item => {
        item.status === 'CONCLUÍDO' ? finished.push(item.status) : null
      })

      const verify = [...new Set(finished)]

      if (verify[0] === 'CONCLUÍDO') {
        return false
      }
      return true
    },

    async toChangeView(item) {
      this.$router.push({ name: 'negociacao-cliente', params: { id: item.cliente_id } })
    },

    async preview(item) {
      this.urlPreview = `http://192.168.254.205/anexos/${item.numero_anexo}`
      this.$refs.preview.show()
    },

    async openModalStatusAnexo() {
      await this.$refs.statusAnexo.show()
    },

    async updateStatusAnexo(statusAnexo) {
      const idsRow = []
      let idOperacao = []
      this.selected.map(item => {
        idsRow.push(item.id)
      })

      this.selected.map(item => {
        const objParse = JSON.parse(item.info_pendencia)
        idOperacao = objParse.id_operacao
      })

      const dataAnexo = {
        id: idsRow,
        status: statusAnexo,
      }

      await axios.put('api/v1/anexos/update', dataAnexo, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(() => {
        this.selected = []
        this.$swal({
          title: 'SUCESSO!',
          text: 'Alteração executada com sucesso',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            comfirmButton: 'btn btn-ptimary',
          },
          buttonStyling: false,
        })
      })

      if (statusAnexo === 'EM ANÁLISE') {
        const body = {
          ops_ids: idOperacao,
          motivo_pendencia_id: 1,
          description: 'PAGAMENTO EM ANÁLISE',
          motivoId: 12,
        }

        await axios.put('api/v1/operacoes/update/lote/motivo/pendencia/', body, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          },
        }).then(() => {
          this.$swal({
            title: 'Pendência alterada!',
            text: 'Alteração executada com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
          })
        }).catch(() => {
          this.$swal({
            title: 'ERROR!',
            text: 'Erro ao executar alteração!',
            icon: 'error',
            customClass: {
              comfirmButton: 'btn btn-danger',
            },
          })
        })

        const obj = {
          operacoes_id: idOperacao,
          observacao: 'Modificado status da pendência pelo anexo',
          status: 'INICIADO',
        }

        await axios.post('api/v1/log/pendencias/store', obj, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
            accept: 'application/json',
          },
        }).then(() => {
          this.$swal({
            title: 'Pendencia modificada!',
            icon: 'success',
            text: 'Novo cadastro adicionado!',
            timer: 1500,
            showConfirmButton: false,
          })
        }).catch(() => {
          this.$swal({
            title: 'ERRO!',
            icon: 'error',
            text: 'Ocorreu um erro durante o cadastro!',
            customClass: {
              confimButton: 'btn btn-danger',
            },
          })
        })
      }

      if (statusAnexo === 'CONCLUÍDO') {
        const body = {
          ops_ids: idOperacao,
          motivo_pendencia_id: null,
          description: '',
          motivoId: 12,
        }

        await axios.put('api/v1/operacoes/update/lote/motivo/pendencia/', body, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          },
        }).then(() => {
          this.$swal({
            title: 'Pendência alterada!',
            text: 'Alteração executada com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
          })
        }).catch(() => {
          this.$swal({
            title: 'ERROR!',
            text: 'Erro ao executar alteração!',
            icon: 'error',
            customClass: {
              comfirmButton: 'btn btn-danger',
            },
          })
        })

        const obj = {
          operacoes_id: idOperacao,
          observacao: 'Finalização da pendência',
          status: 'CONCLUIDO',
        }

        await axios.post('api/v1/log/pendencias/store', obj, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
            accept: 'application/json',
          },
        }).then(() => {
          this.$swal({
            title: 'Pendencia modificada!',
            icon: 'success',
            text: 'Novo cadastro adicionado!',
            timer: 1500,
            showConfirmButton: false,
          })
        }).catch(() => {
          this.$swal({
            title: 'ERRO!',
            icon: 'error',
            text: 'Ocorreu um erro durante o cadastro!',
            customClass: {
              confimButton: 'btn btn-danger',
            },
          })
        })
      }
      // await this.updateTable()
    },

    async sendAnexo(statusAnexo) {
      const idsRow = []
      this.selected.map(item => {
        idsRow.push(item.id)
      })

      const dataAnexo = {
        anexo_id: parseInt(idsRow),
        status: statusAnexo,
        usuario: localStorage.getItem('userName'),
      }
      await axios.post('api/v1/log/anexos/store', dataAnexo, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(() => {
        this.selected = []
        this.$swal({
          title: 'Status modificado!',
          text: 'Status cadastrado com sucesso',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            comfirmButton: 'btn btn-ptimary',
          },
          buttonStyling: false,
        })
      })
      await this.updateTable()
    },

    logAnexos(rowItem) {
      const body = {
        anexo_id: rowItem.id,
      }
      axios.post('api/v1/log/anexos/filtro', body, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }).then(res => {
        const data = res.data.dados
        this.rend = data.map(item => ({
          anexo_id: item.anexo_id,
          data_criação: this.dataHora(item.created_at, true),
          id: item.id,
          status: item.status,
          data_atualização: this.dataHora(item.updated_at, true),
          user_id: item.user_id,
          nome_usuario: item.usuario,
        }))
      })

      this.$refs.viewDataLog.show()
    },

    limparSelecao() {
      this.selected = []
    },

    selecionarTodos() {
      this.selected = []

      this.items.map(item => {
        this.selected.push(item)
      })
      // this.items[0].status = 'INICIADO'
    },
    async exportFile() {
      const file = await this.getDataToExported()
      if (file) {
        saveAs(file)
      }
    },
    async getDataToExported() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Anexos')
      const itemsPlan = this.listFiltered

      worksheet.columns = [
        { key: 'cliente_id', header: 'CLIENTE ID' },
        { key: 'info_pendencia', header: 'OPERAÇÕES' },
        { key: 'nome', header: 'NOME ARQUIVO' },
        { key: 'nome_cliente', header: 'CLIENTE NOME' },
        { key: 'nome_credor', header: 'CREDOR' },
        { key: 'status', header: 'STATUS' },
      ]

      itemsPlan.map(item => {
        worksheet.addRow([
          item.cliente_id,
          this.resolveJsonInfoPendencia(item.info_pendencia),
          item.nome,
          item.nome_cliente,
          item.nome_credor,
          item.status,
        ])
      })

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })

      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], 'Relatório de Anexos', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },
    resolveJsonInfoPendencia(json) {
      const decodeJson = JSON.parse(json)
      return decodeJson.numero_operacao.map(item => item).join(', ')
    },

  },

}
</script>

<style>
  #myFrame {
    width:100%;
    height:800px;
    border: none;
  }

</style>
