<template>
  <div>
    <b-row>
      <b-col cols="12">
        Descrição da Carteira:
        <b-form-input
          v-model="descricao"
        />
      </b-col>
    </b-row>
    <b-row class="mt-1 mb-2">
      <b-col>
        Supervisor da Carteira:
        <v-select
          v-model="supervisor.select"
          label="nome"
          :options="supervisor.options"
        />
      </b-col>
      <b-col
        md="3"
      >
        Meta da Carteira:
        <b-form-input
          v-model="metaCarteira"
          disabled
        />
      </b-col>
    </b-row>
    <b-row
      class="mb-2"
    >
      <b-col>
        <b-tabs
          pills
          fill
        >
          <b-tab
            title="Executivos"
          >
            <b-form-input
              v-model="filterExecutivo"
              placeholder="Pesquisar"
              type="search"
              size="sm"
              class="mb-1"
            />
            <b-table
              sticky-header
              responsive
              :filter="filterExecutivo"
              :fields="fieldsExecutivo"
              :items="itemsExecutivo"
            >
              <template #cell(id)="data">
                <b-form-checkbox
                  v-model="selectExecutivo"
                  :value="data.item"
                />
              </template>
              <template #cell(meta)="data">
                <b-form-input
                  v-model="data.item.meta"
                  type="number"
                />
              </template>
            </b-table>
          </b-tab>
          <b-tab
            title="Credores"
          >
            <b-form-input
              v-model="filterCredor"
              placeholder="Pesquisar"
              type="search"
              size="sm"
              class="mb-1"
            />
            <b-table
              sticky-header
              responsive
              :filter="filterCredor"
              :fields="fieldsCredor"
              :items="itemsCredor"
            >
              <template #cell(id)="data">
                <b-form-checkbox
                  v-model="selectCredor"
                  :value="data.item"
                />
              </template>
              <template #cell(meta)="data">
                <b-form-input
                  v-model="data.item.meta"
                  type="number"
                />
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <hr>
    <b-row
      align-h="end"
    >
      <b-col
        cols="auto"
      >
        <b-button
          variant="primary"
          @click="storeCarteira"
        >
          <feather-icon
            icon="SaveIcon"
            class="mr-50"
          />
          Salvar
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  // BFormTextarea,
  BFormCheckbox,
  BFormInput,
  BTabs,
  BTab,
  BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from '@/../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    // BFormTextarea,
    BFormCheckbox,
    BFormInput,
    BTabs,
    BTab,
    BTable,
    vSelect,
  },
  props: {
    updateTable: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      descricao: '',
      supervisor: {
        select: {},
        options: [],
      },
      fieldsExecutivo: [
        {
          key: 'id', label: 'check',
        },
        {
          key: 'nome', label: 'executivo',
        },
        {
          key: 'meta',
        },
      ],
      filterExecutivo: '',
      itemsExecutivo: [],
      selectExecutivo: [],

      fieldsCredor: [
        {
          key: 'id', label: 'check',
        },
        {
          key: 'nome', label: 'credor',
        },
        {
          key: 'razao_social',
        },
        {
          key: 'meta',
        },
      ],
      filterCredor: '',
      itemsCredor: [],
      selectCredor: [],
    }
  },
  computed: {
    metaCarteira() {
      let metaGeral = 0
      this.selectCredor.map(dt => {
        if (dt.meta === '') {
          dt.meta = 0
        }
        metaGeral += parseFloat(dt.meta)
      })
      return metaGeral.toFixed(2)
    },
  },
  async created() {
    // await this.getExecutivos()
    await this.getCarteira()
  },
  methods: {
    // async getExecutivos() {
    //   await axios.get('api/v1/executivo', {
    //     headers: {
    //       Authorization: `Bearer ${localStorage.token}`,
    //       accept: 'application/json',
    //     },
    //   }).then(res => {
    //     res.data.map(dt => {
    //       this.itemsExecutivo.push({
    //         id: dt.id,
    //         nome: dt.nome,
    //         meta: 0,
    //       })
    //     })
    //   })
    // },

    getCarteira() {
      axios.get('api/v1/carteira/create', {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      }).then(res => {
        this.supervisor.options = res.data.dados.supervisores
        res.data.dados.credores.map(dt => {
          this.itemsCredor.push({
            id: dt.id,
            nome: dt.nome,
            razao_social: dt.razao_social,
            meta: 0,
          })
        })

        res.data.dados.executivos.map(dt => {
          this.itemsExecutivo.push({
            id: dt.id,
            nome: dt.nome,
            meta: 0,
          })
        })
      })
    },

    async storeCarteira() {
      const credor = []
      const executivo = []

      await this.selectCredor.map(dt => {
        credor.push({
          id: dt.id,
          meta: dt.meta,
        })
      })
      await this.selectExecutivo.map(dt => {
        executivo.push({
          id: dt.id,
          meta: dt.meta,
        })
      })

      const body = {
        executivo_cobranca_id: this.supervisor.select.id,
        descricao: this.descricao,
        meta: parseFloat(this.metaCarteira),
        credores: JSON.stringify(credor),
        executivos: JSON.stringify(executivo),
      }

      axios.post('api/v1/carteira/store', body, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }).then(() => {
        this.updateTable()
        this.$swal({
          title: 'Carteira Salva!',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false,
        })
      })
        .catch(() => {
          this.$swal({
            title: 'ERRO',
            text: 'Ocorreu um erro durante cadastro da carteira!',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
  },
}
</script>
