<template>
  <div>
    <!-- LISTA CARTEIRAS -->
    <div v-if="screen">
      <b-row
        align-h="end"
        class="mb-2"
      >
        <b-col
          cols="auto"
        >
          <b-button
            variant="primary"
            @click="$refs.novaCarteira.show()"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            Carteira
          </b-button>
          <b-modal
            ref="novaCarteira"
            hide-footer
            size="xl"
          >
            <nova-carteira
              :update-table="getCarteiras"
            />
          </b-modal>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-table
            hover
            striped
            bordered
            responsive
            :fields="fields"
            :items="carteiras"
            @row-clicked="getCarteira"
          >
            <template #cell(progress)="data">
              {{ data.item.progress.toFixed(2) }} %
            </template>
            <template #cell(liquidado)="data">
              {{ valorBr(parseFloat(data.item.liquidado), true) }}
            </template>
            <template #cell(meta)="data">
              {{ valorBr(parseFloat(data.item.meta), true) }}
            </template>
          </b-table>
        </b-col>
      </b-row>
    </div>
    <!-- CARTEIRA VIEW -->
    <div v-else>
      <b-row
        align-h="start"
      >
        <b-col cols="auto">
          <b-button
            variant="outline-primary"
            @click="screen = true"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              class="mr-50"
            />
            Voltar
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <carteira-progresso
            :progress="carteiraDados"
          />
        </b-col>
      </b-row>
      <b-row
        align-h="between"
      >
        <b-col cols="5">
          <b-row>
            <b-col
              cols="12"
            >
              <h4 class="d-flex justify-content-center mb-1">
                Executivos
              </h4>
              <CircleProgress
                v-for="(item, i) in executivosDados"
                :key="item"
                :item="executivosDados[i]"
                class="mb-1"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="5">
          <b-row>
            <b-col
              cols="12"
            >
              <h4 class="d-flex justify-content-center mb-1">
                Credores
              </h4>
              <CircleProgress
                v-for="(item, i) in credoresDados"
                :key="item"
                :item="credoresDados[i]"
                class="mb-1"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BButton,
  BTable,
} from 'bootstrap-vue'
import axios from '@/../axios-auth'
import NovaCarteira from './NovaCarteira.vue'
import CarteiraProgresso from './CarteriaProgresso.vue'
import CircleProgress from './CircleProgress.vue'

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BButton,
    BTable,
    NovaCarteira,
    CarteiraProgresso,
    CircleProgress,
  },
  data() {
    return {
      carteiraDados: {
        name: 'Default',
        max: 80000,
        value: 16000,
      },
      executivosDados: [],
      credoresDados: [],

      fields: [
        {
          key: 'id',
        },
        {
          key: 'description', label: 'descrição',
        },
        {
          key: 'supervisor',
        },
        {
          key: 'progress', class: 'text-right',
        },
        {
          key: 'liquidado', class: 'text-right',
        },
        {
          key: 'meta', class: 'text-right',
        },
      ],
      carteiras: [],

      screen: true,
    }
  },
  created() {
    this.getCarteiras()
  },
  methods: {
    async getCarteira(item) {
      this.screen = false

      this.carteiraDados = {
        name: 'Default',
        max: 80000,
        value: 16000,
      }
      this.executivosDados = []
      this.credoresDados = []

      await axios.get(`api/v1/carteira/get/${item.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      }).then(async res => {
        this.carteiraDados.value = res.data.dados.liquidado
        this.carteiraDados.max = parseFloat(res.data.dados.meta)
        this.carteiraDados.name = res.data.dados.description

        // this.executivosDados = Object.values(res.data.dados.executivos)

        // this.credoresDados = Object.values(res.data.dados.credores)

        const keysCredores = Object.keys(res.data.dados.credores)
        await keysCredores.map(dt => {
          this.credoresDados.unshift(res.data.dados.credores[dt])
        })

        const keysExecutivos = Object.keys(res.data.dados.executivos)
        await keysExecutivos.map(dt => {
          this.executivosDados.unshift(res.data.dados.executivos[dt])
        })
      })
    },

    async getCarteiras() {
      this.carteiras = []

      try {
        this.$refs.novaCarteira.hide()
      } finally {
        await axios.get('api/v1/carteira', {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        }).then(async res => {
          await res.data.dados.map(dt => {
            this.carteiras.push(dt)
          })
        })
      }
    },
  },
}
</script>
