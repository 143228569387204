<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row class="mb-2">
            <b-col
              v-for="(item, index) in propostas"
              :key="item"
              cols="auto"
              class="ml-2 cursor-pointer"
              @click="selectTable(index)"
            >
              <b-media>
                <template #aside>
                  <b-avatar
                    size="45"
                    :variant="`light-${resolveInvoiceStatusVariantAndIcon(item.title).variant}`"
                  >
                    <feather-icon
                      :icon="resolveInvoiceStatusVariantAndIcon(item.title).icon"
                    />
                  </b-avatar>
                </template>
                <b-media-body>
                  <h4 class="font-weight-bolder mb-0">
                    {{ item.items.length }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ item.title }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
          <b-table
            :fields="fields"
            :items="propostas[table].items"
            striped
            hover
            small
            responsive
            sticky-header="40vh"
          >
            <template #cell(numero_proposta)="data">
              <b-link
                class="font-weight-bold"
              >
                #{{ data.item.numero_proposta }}
              </b-link>
            </template>

            <template #cell(status)="data">
              <b-avatar
                :id="`invoice-row-${data.item.id}`"
                size="32"
                :variant="`light-${resolveInvoiceStatusVariantAndIcon(data.item.status).variant}`"
              >
                <feather-icon
                  :icon="resolveInvoiceStatusVariantAndIcon(data.item.status).icon"
                />
                <b-tooltip
                  :target="`invoice-row-${data.item.id}`"
                  placement="top"
                >
                  <p class="mb-0">
                    {{ data.item.status }}
                  </p>
                </b-tooltip>
              </b-avatar>
            </template>

            <template #cell(cliente)="data">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="32"
                    :text="getInitials(data.item.cliente.nome)"
                    :variant="`light-${resolveClientAvatarVariant(data.item.status)}`"
                  />
                </template>
                <span class="font-weight-bold d-block text-nowrap">
                  {{ data.item.cliente.nome }}
                </span>
              </b-media>
            </template>

            <template #cell(data_proposta)="data">
              {{ dataHora(data.item.created_at) }}
            </template>

            <template #cell(total)="data">
              {{ data.item.valor_nominal ? valorBr(parseFloat(data.item.valor_nominal), true) : '' }}
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BMedia,
  BAvatar,
  BTooltip,
  BLink,
} from 'bootstrap-vue'

import api from '@/../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BMedia,
    BAvatar,
    BTooltip,
    BLink,
  },
  data() {
    return {
      fields: [
        { key: 'numero_proposta', label: 'Número Proposta' },
        { key: 'data_proposta', label: 'Data Proposta' },
        { key: 'nome_cliente', label: 'Cliente' },
        { key: 'nome_credor', label: 'Credor' },
        { key: 'total', label: 'Total', class: 'text-right' },
        { key: 'status', label: 'Status', class: 'text-center' },
      ],
      propostas: [
        {
          title: 'Todos',
          items: [],
        },
        {
          title: 'Analise',
          items: [],
        },
        {
          title: 'Aprovado',
          items: [],
        },
        {
          title: 'Reprovado',
          items: [],
        },
      ],

      table: 0,
    }
  },
  created() {
    this.getInvoices()
  },
  methods: {
    selectTable(value) {
      this.table = value
    },
    getInvoices() {
      const { executivo_id } = localStorage
      // eslint-disable-next-line camelcase
      api.get(`api/v1/proposta/executivo/listar/${executivo_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          Accept: 'application/json',
        },
      }).then(res => {
        const { dados } = res.data
        // this.items = dados
        this.propostas = [
          {
            title: 'Todos',
            items: [],
          },
          {
            title: 'Analise',
            items: [],
          },
          {
            title: 'Aprovado',
            items: [],
          },
          {
            title: 'Reprovado',
            items: [],
          },
        ]
        dados.map(dt => {
          this.propostas[0].items.unshift(dt)

          if (dt.status === 'Analise') {
            this.propostas[1].items.unshift(dt)
          } else if (dt.status === 'Aprovado') {
            this.propostas[2].items.unshift(dt)
          } else if (dt.status === 'Reprovado') {
            this.propostas[3].items.unshift(dt)
          }
        })
      })
    },

    resolveInvoiceStatusVariantAndIcon(status) {
      if (status === 'Analise') return { variant: 'warning', icon: 'PieChartIcon' }
      if (status === 'Aprovado') return { variant: 'success', icon: 'CheckCircleIcon' }
      if (status === 'Reprovado') return { variant: 'danger', icon: 'InfoIcon' }
      return { variant: 'secondary', icon: 'XIcon' }
    },

    resolveClientAvatarVariant(status) {
      if (status === 'Aprovado') return 'danger'
      if (status === 'Reprovado') return 'primary'
      if (status === 'Analise') return 'warning'
      return 'primary'
    },
  },
}
</script>
