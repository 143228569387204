<template>
  <app-timeline>
    <app-timeline-item
      v-for="item in rend"
      :key="item.id"
      :variant="item.status === 'Aprovado' ? 'success' : item.status === 'Analise Credor'? 'warning' : item.status === 'Analise Supervisao' ? 'secondary' : item.status === 'Reprovado' ? 'danger' : 'info'"
    >
      <div>
        <h6>
          {{ item.id }}
        </h6>

        <b-list-group
          flush
          class="mt-1"
        >
          <b-list-group-item
            href="#"
            class="d-flex justify-content-between align-items-center bg-transparent"
          >
            <span><strong>Cliente: </strong>{{ item.cliente }}</span>
          </b-list-group-item>
          <b-list-group-item
            href="#"
            class="d-flex justify-content-between align-items-center bg-transparent"
          >
            <span><strong>Número Proposta: </strong>{{ item.numero_proposta }}</span>
          </b-list-group-item>
          <b-list-group-item
            href="#"
            class="d-flex justify-content-between align-items-center bg-transparent"
          >
            <span><strong>Status: </strong>{{ item.status }}</span>
          </b-list-group-item>
          <b-list-group-item
            href="#"
            class="d-flex justify-content-between align-items-center bg-transparent"
          >
            <span><strong>Data: </strong>{{ item.created_at }}</span>
          </b-list-group-item>
          <b-list-group-item
            href="#"
            class="d-flex justify-content-between align-items-center bg-transparent"
          >
            <span><strong>Atualização: </strong>{{ item.updated_at }}</span>
          </b-list-group-item>
          <b-list-group-item
            href="#"
            class="d-flex justify-content-between align-items-center bg-transparent"
          >
            <span><strong>Nome usuário: </strong>{{ item.usuario }}</span>
          </b-list-group-item>

          <br>
        </b-list-group>

      </div>
    </app-timeline-item>
  </app-timeline>
</template>

<script>
import {
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

import api from '@/../axios-auth'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BListGroup,
    BListGroupItem,
  },
  props: {
    idProposta: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      rend: {
        id: '',
        cliente: '',
        numero_proposta: '',
        status: '',
        created_at: '',
        updated_at: '',
      },
    }
  },
  async mounted() {
    api.get(`api/v1/proposta/listar/log/${this.idProposta}`, {
      headers: {
        Authorization: `Bearer ${localStorage.token}`,
      },
    }).then(res => {
      const data = res.data.dados
      this.rend = data.map(item => ({
        id: item.id,
        cliente: item.nome_cliente,
        numero_proposta: item.numero_proposta,
        status: item.status,
        created_at: this.dataHora(item.created_at),
        updated_at: this.dataHora(item.updated_at),
        usuario: item.usuario,
      }))
    })
  },
}
</script>
