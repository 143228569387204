<template hidden>
  <div hidden>
    <b-card>
      <b-card-header>
        <h4 class="mb-0">
          Migração
        </h4>
      </b-card-header>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <!-- apex chart -->
          <vue-apex-charts
            type="radialBar"
            height="245"
            class="my-2"
            :options="goalOverviewRadialBar"
            :series="series"
          />
          <b-row class="text-center mx-0">
            <b-col
              cols="6"
              class="border-top border-right d-flex align-items-between flex-column py-1"
            >
              <b-card-text class="text-muted mb-0">
                Completados
              </b-card-text>
              <h3 class="font-weight-bolder mb-0">
                {{ migrados }}
              </h3>
            </b-col>

            <b-col
              cols="6"
              class="border-top d-flex align-items-between flex-column py-1"
            >
              <b-card-text class="text-muted mb-0">
                Em progresso
              </b-card-text>
              <h3 class="font-weight-bolder mb-0">
                {{ naoMigrados }}
              </h3>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="4">
          <b-row class="avg-sessions pt-50">
            <b-col
              v-for="(item, index) in items"
              :key="index"
              cols="6"
              class="mb-2 mt-2 cursor-pointer"
              @click="itemClick(index, item.subtitle)"
            >
              <b-card-text class="mb-50">
                {{ item.subtitle }}
              </b-card-text>
              <b-progress
                :value="item.valor"
                max="44"
                :variant="item.variant"
                height="6px"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      ref="my-modal"
      hide-footer
      :title=" `${titulo}`"
    >
      <div>
        <b-table
          striped
          hover
          :fields="fields"
          :items="listCred"
        >

          <template #cell(opcoes)="row">
            <feather-icon
              variant="primary"
              icon="EyeIcon"
              class="cursor-pointer"
              @click="viewCredor(row.item.id)"
            />
          </template>
        </b-table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BRow, BCol, BCardText, BProgress, BTable,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import axios from '@/../axios-auth'

const $strokeColor = '#ebe9f1'
const $textHeadingColor = '#5e5873'
const $goalStrokeColor2 = '#51e5a8'
/* eslint-disable global-require */
export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
    BProgress,
    BTable,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      series: [],
      migrados: [],
      naoMigrados: [],
      items: [
        {
          subtitle: 'GRUPO A',
          valor: 0,
        },
        {
          subtitle: 'GRUPO B',
          variant: 'warning',
          valor: 0,
        },
        {
          subtitle: 'GRUPO C',
          variant: 'danger',
          valor: 0,
        },
        {
          subtitle: 'GRUPO D',
          variant: 'success',
          valor: 0,
        },
      ],
      goalOverviewRadialBar: {
        chart: {
          height: 245,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [$goalStrokeColor2],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '77%',
            },
            track: {
              background: $strokeColor,
              strokeWidth: '50%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: $textHeadingColor,
                fontSize: '2.86rem',
                fontWeight: '600',
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: [$themeColors.success],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
      fields: [
        { key: 'nome', label: 'Instituição' },
        { key: 'opcoes' },
      ],
      listCred: [],
      grupoA: [],
      grupoB: [],
      grupoC: [],
      grupoD: [],
      titulo: '',
    }
  },
  created() {
    axios.get('api/v1/credor/dados/importados', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    })
      .then(res => {
        const migrados = []
        const naoMigrados = []
        let progressMigrados = 0
        let progressNaoMigrados = 0
        // Alimentando o grafico Goal overview
        res.data.dados.map(item => {
          if (item.status) {
            migrados.push(item)
            progressMigrados += 1
          } else {
            naoMigrados.push(item)
            progressNaoMigrados += 1
          }
        })
        const progress = (progressMigrados / progressNaoMigrados) * 100
        this.series = [progress.toFixed(1)]
        this.migrados = migrados.length
        this.naoMigrados = naoMigrados.length
        const groupA = []
        const groupB = []
        const groupC = []
        const groupD = []
        res.data.dados.map(group => {
          if (group.status_em_processo === 1) {
            groupA.push({
              id: group.id,
              nome: group.nome,
            })
          }
          if (group.status_em_processo === 2) {
            groupB.push({
              id: group.id,
              nome: group.nome,
            })
          }
          if (group.status_em_processo === 3) {
            groupC.push({
              id: group.id,
              nome: group.nome,
            })
          }
          if (group.status_em_processo === 4) {
            groupD.push({
              id: group.id,
              nome: group.nome,
            })
          }
        })
        this.grupoA = groupA
        this.grupoB = groupB
        this.grupoC = groupC
        this.grupoD = groupD
        // Alimentando a barra de progresso dos grupos
        this.items[0].valor = groupA.length
        this.items[1].valor = groupB.length
        this.items[2].valor = groupC.length
        this.items[3].valor = groupD.length
      })
  },
  methods: {
    itemClick(index, title) {
      if (index === 0) {
        this.titulo = title
        this.listCred = this.grupoA
        this.$refs['my-modal'].show()
      } else if (index === 1) {
        this.titulo = title
        this.listCred = this.grupoB
        this.$refs['my-modal'].show()
      } else if (index === 2) {
        this.titulo = title
        this.listCred = this.grupoC
        this.$refs['my-modal'].show()
      } else if (index === 3) {
        this.titulo = title
        this.listCred = this.grupoD
        this.$refs['my-modal'].show()
      }
    },
    async viewCredor(item) {
      this.$router.push({ name: 'credor-view', params: { id: item } })
    },

  },
}
</script>
