<template>
  <b-card
    v-if="dados"
    no-body
  >
    <b-card-header>
      <h4 class="mb-0">
        {{ title }}
      </h4>
    </b-card-header>

    <!-- apex chart -->
    <vue-apex-charts
      type="radialBar"
      height="245"
      class="my-2"
      :options="goalOverviewRadialBar"
      :series="dados.series"
    />
    <b-row class="text-center mx-0">
      <b-col
        cols="6"
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          Realizado
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ valorBr(dados.completed, true) }}
        </h3>
      </b-col>

      <b-col
        cols="6"
        class="border-top d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          Valor Meta
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ valorBr(dados.inProgress, true) }}
        </h3>
      </b-col>
    </b-row>
    {{ data }}
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
// import axios from '@/../axios-auth'

// import store from '@/store/index'

// const { skin } = store.state.appConfig.layout
const $strokeColor = '#ebe9f1'
const $textHeadingColor = '#5e5873'
const $goalStrokeColor2 = '#51e5a8'
export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
  },
  props: {
    title: {
      type: String,
      default: 'Meta',
    },
    dados: {
      type: Object,
      required: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      // data: {
      //   series: this.dados.series,
      //   completed: this.dados.completed,
      //   inProgress: this.dados.inProgress,
      // },
      goalOverviewRadialBar: {
        chart: {
          height: 245,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [$goalStrokeColor2],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '77%',
            },
            track: {
              background: $strokeColor,
              strokeWidth: '50%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: $textHeadingColor,
                fontSize: '2.86rem',
                fontWeight: '600',
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: [$themeColors.success],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
    }
  },
  // created() {
  //   this.executivo_cobranca = localStorage.getItem('executivo_id')
  //   this.data.inProgress = parseFloat(localStorage.getItem('meta'))
  //   axios.get(`api/v1/executivo/acordos/mes/${this.executivo_cobranca}`, {
  //     headers: {
  //       Authorization: 'Bearer '.concat(localStorage.getItem('token')),
  //       accept: 'application/json',
  //     },
  //   }).then(async res => {
  //     const { dados } = res.data
  //     const somaLiquidado = dados.map(item => {
  //       let valorReturn = 0
  //       if (item.status === 'Liquidado') {
  //         valorReturn = parseFloat(item.valor_nominal)
  //       }
  //       return valorReturn
  //     }).reduce((prev, curr) => prev + curr, 0)
  //     this.data.completed = somaLiquidado
  //     const serie = (((somaLiquidado * 100) / this.data.inProgress) % 2 === 0 ? ((somaLiquidado * 100) / this.data.inProgress) : ((somaLiquidado * 100) / this.data.inProgress).toFixed(2))

  //     if (somaLiquidado / this.data.inProgress) {
  //       this.data.series.push(serie)
  //     } else {
  //       this.data.series.push(0)
  //     }

  //     // this.data.series.push(serie)
  //     // this.data.series.push(((somaLiquidado * 100) / this.data.inProgress) % 2 === 0 ? ((somaLiquidado * 100) / this.data.inProgress) : ((somaLiquidado * 100) / this.data.inProgress).toFixed(2))
  //   })
  // },
}
</script>
