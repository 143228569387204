<template>
  <div>
    <Supervisor
      v-if="layout === 'supervisor'"
    />
    <Executivo
      v-if="layout === 'executivo'"
    />
  </div>
</template>
<script>
import Executivo from './Dash.vue'
import Supervisor from '../supervisor/DashboardSupervisor.vue'

export default {
  components: {
    Executivo,
    Supervisor,
  },
  data() {
    return {
      layout: '',
    }
  },
  created() {
    this.selectDash()
  },
  methods: {
    async selectDash() {
      if (localStorage.funcao === 'Executivo de Cobrança') {
        this.layout = 'executivo'
      } else if (localStorage.funcao === 'Supervisor(a) Cobrança') {
        this.layout = 'supervisor'
      }
    },
  },
}
</script>
