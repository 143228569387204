<template>
  <div>
    <b-card>
      <b-media>
        <template #aside>
          <b-avatar
            :text="getInitials(progress.name)"
            variant="primary"
            size="70"
          />
        </template>
        <h5>{{ progress.name }}</h5>
        <b-progress
          variant="success"
          :max="progress.max"
          animated
          :height="'1vh'"
        >
          <b-progress-bar
            :value="progress.value"
          >
            <strong>{{ `${((progress.value / progress.max) * 100).toFixed(2)} %` }}</strong>
          </b-progress-bar>
        </b-progress>
        <strong>{{ valorBr(progress.value, true) }} / {{ valorBr(progress.max, true) }}</strong>
      </b-media>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BProgress,
  BProgressBar,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BProgress,
    BProgressBar,
  },
  props: {
    progress: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
}
</script>
