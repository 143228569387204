<template>
  <div>
    <div>
      <b-media>
        <template
          #aside
        >
          <b-avatar
            :text="getInitials(item.name)"
            size="45"
            class="mr-1"
          />
          <h6>{{ item.name }}</h6>
        </template>
        <div
          class="d-flex align-items-center justify-content-end"
        >
          <span class="font-weight-bold text-body-heading mr-1"> {{ parseInt(item.progress) }} %</span>
          <ApexCharts
            type="radialBar"
            height="30"
            width="30"
            :options="chartOptions"
            :series="[item.progress]"
          />
        </div>
      </b-media>
    </div>
  </div>
</template>
<script>
import {
  BAvatar,
  BMedia,
} from 'bootstrap-vue'
import ApexCharts from 'vue-apexcharts'

export default {
  components: {
    BAvatar,
    BMedia,
    ApexCharts,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      chartOptions: {
        grid: {
          show: false,
          padding: {
            left: -15,
            right: -15,
            top: -12,
            bottom: -15,
          },
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: '22%',
            },
            track: {
              background: '#e9ecef',
            },
            dataLabels: {
              showOn: 'always',
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        stroke: {
          lineCap: 'round',
        },
      },
    }
  },
}
</script>
