<template>
  <div>
    <b-row>
      <b-col md="9">
        <b-row>
          <b-col>
            <label for="">Condição</label>
            <b-form-input
              disabled
              :value="parcelas.length > 1 ? 'Parcelado' : 'À vista'"
            />
          </b-col>
          <b-col>
            <label for="">Forma de pagamento</label>
            <b-form-input
              disabled
              :value="formaPagamento.label || ''"
            />
          </b-col>
          <b-col>
            <label for="">Data da entrada</label>
            <b-form-input
              disabled
              :value="parcelas.length > 0 ? americaDate(parcelas[0].data_vencimento) : ''"
            />
          </b-col>
          <b-col>
            <label for="">Valor Entrada</label>
            <b-form-input
              disabled
              :value="acordo.valor_entrada || ''"
            />
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col>
            <label for="">Número de parcelas</label>
            <b-form-input
              disabled
              :value="parcelas.length"
            />
          </b-col>
          <b-col>
            <label for="">Juros</label>
            <b-form-input
              disabled
              :value="acordo.juros_porcento ? acordo.juros_porcento.toString() : ''"
            />
          </b-col>
          <b-col>
            <label for="">Multa</label>
            <b-form-input
              disabled
              :value="acordo.multas_porcento ? acordo.multas_porcento.toString() : ''"
            />
          </b-col>
          <b-col>
            <label for="">Hon. Variável</label>
            <b-form-input
              disabled
              :value="acordo.honorarios_porcento || ''"
            />
          </b-col>
          <b-col>
            <label for="">Desconto</label>
            <b-form-input
              disabled
              :value="acordo.desconto_porcento ? acordo.desconto_porcento.toString() : ''"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <label for="">Parcelas</label>
        <b-form-textarea
          class="text-uppercase"
          readonly
          :value="parcelasDemonstrativo"
          placeholder=""
          rows="4"
        />
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col>
        <b-table
          responsive
          striped
          :fields="fields"
          :items="operacoes"
        >
          <template #cell(data_vencimento)="data">
            {{ americaDate(data.item.data_vencimento, true) }}
          </template>

          <template #cell(valor_nominal)="data">
            {{ valorBr(parseFloat(data.item.valor_nominal), false) }}
          </template>

          <template #cell(valor_juros)="data">
            {{ valorBr(parseFloat(data.item.valor_juros), false) }}
          </template>

          <template #cell(valor_multa)="data">
            {{ valorBr(parseFloat(data.item.valor_multa), false) }}
          </template>

          <template #cell(valor_honorario)="data">
            {{ valorBr(parseFloat(data.item.valor_honorario), false) }}
          </template>

          <template #cell(desconto)="data">
            {{ valorBr(parseFloat(data.item.desconto), false) }}
          </template>

          <template #cell(valor_atualizado)="data">
            {{ valorBr(parseFloat(data.item.valor_atualizado), false) }}
          </template>
        </b-table>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col sm="2">
        <h6><strong>Valor da Dívida</strong></h6>
        <h3>{{ valorBr(acordo.totalNominal, true) }}</h3>
      </b-col>
      <b-col sm="2">
        <h6><strong>Juros</strong></h6>
        <h3>{{ valorBr(acordo.juros, true) }}</h3>
      </b-col>
      <b-col sm="2">
        <h6><strong>Multa</strong></h6>
        <h3>{{ valorBr(acordo.multas, true) }}</h3>
      </b-col>
      <b-col sm="2">
        <h6>
          <strong>Hon. Variável</strong>
        </h6>
        <h3>{{ valorBr(acordo.honorarios, true) }}</h3>
      </b-col>
      <b-col sm="2">
        <h6>
          <strong>Desconto</strong>
        </h6>
        <h3>{{ valorBr(acordo.desconto, true) }}</h3>
      </b-col>
      <b-col sm="2">
        <h6><strong>Valor da Negociação</strong></h6>
        <h3>{{ valorBr(acordo.valor_nominal, true) }}</h3>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import { mapState } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BFormInput,
    BFormTextarea,
  },
  data() {
    return {
      fields: [
        { key: 'numero_operacao', label: 'operacao', class: 'text-center table-adjust' },
        { key: 'aluno', label: 'aluno', class: 'text-center' },
        { key: 'data_vencimento', label: 'vencimento', class: 'text-center' },
        { key: 'valor_nominal', label: 'Dívida', class: 'text-center' },
        { key: 'valor_juros', label: 'juros', class: 'text-center' },
        { key: 'valor_multa', label: 'multa', class: 'text-center' },
        { key: 'valor_honorario', label: 'Hon. Variável', class: 'text-center' },
        { key: 'valor_desconto', label: 'Desc', class: 'text-center' },
        { key: 'valor_atualizado', label: 'total' },
      ],
    }
  },
  computed: {
    ...mapState({
      operacoes: state => state.proposta.operacoes,
      parcelas: state => state.proposta.parcelas,
      acordo: state => state.proposta.acordo,
      formaPagamento: state => state.proposta.formaPagamento,
      simulacao: state => state.proposta.simulacao,
      parametros_contato: state => state.proposta.parametros_contato,
      parcelasOperacoes: state => state.proposta.parcelasOperacoes,
    }),
    parcelasDemonstrativo() {
      let parcelasShow = ''

      this.parcelas.map(parcela => {
        parcelasShow += `${parcela.numero_parcela}º - ${this.valorBr(parseFloat(parcela.valor_nominal), true)} -- ${this.americaDate(parcela.data_vencimento)}\n`
      })

      return parcelasShow
    },
  },
  methods: {
  },
}
</script>
