<template>
  <div>
    <b-col
      md="2"
      offset-md="4"
    >
      <b-alert
        variant="danger"
        show
      >
        <h4 class="alert-heading">
          Clientes Classificados: {{ totalClassificacaoCliente }}
        </h4>
      </b-alert>
    </b-col>
    <b-row class="mb-2">
      <b-col md="4">
        <label>Filtro por classificação</label>
        <v-select
          v-model="clientStatusFilter"
          :options="optionClassification"
          multiple
        />
      </b-col>
      <b-col md="4">
        <label>Filtro por credores</label>
        <v-select
          v-model="credoresFilter"
          :options="optionsCredores"
          multiple
        />
      </b-col>

      <b-col align-self="end">
        <b-button
          variant="outline-warning"
          @click="exportXlsx"
        >
          Exportar excel
        </b-button>
      </b-col>
    </b-row>
    <b-table
      small
      striped
      responsive
      :per-page="perPage"
      :current-page="currentPage"
      :fields="fields"
      :items="listTableFiltered"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @filtered="onFiltered"
    >
      <template #cell(status_cliente_id)="data">
        <b-badge
          :variant="JSON.parse(data.item.status_cliente_id).title ===
            'ANALISE URGENTE' ?
              'light-danger'
            :
              'light-dark'"
        >
          {{ JSON.parse(data.item.status_cliente_id).title }}
        </b-badge>
      </template>
      <template #cell(created_at)="data">
        {{ data.item.created_at ? dataHora(data.item.created_at) : '' }}
      </template>
      <template #cell(updated_at)="data">
        {{ data.item.updated_at ? formatTimezone(data.item.updated_at) : '' }}
      </template>
      <template #cell(ultimo_contato)="data">
        {{ data.item.ultimo_contato ? formatTimezone(data.item.ultimo_contato) : '' }}
      </template>
      <template #cell(data_cadastro_confirmado)="data">
        {{ data.item.data_cadastro_confirmado ? formatTimezone(data.item.data_cadastro_confirmado) : '' }}
      </template>
      <template #cell(visualizar)="data">
        <b-row align-h="center">
          <b-col cols="auto">
            <feather-icon
              variant="primary"
              icon="DollarSignIcon"
              class="cursor-pointer"
              @click="toChangeView(data.item)"
            />
          </b-col>
          <b-col cols="auto">
            <feather-icon
              variant="primary"
              icon="EyeIcon"
              class="cursor-pointer"
              @click="logClassificacao(data.item)"
            />
          </b-col>
        </b-row>
      </template>

    </b-table>

    <!-- Modal de log Classificação -->

    <b-modal
      ref="viewDataLog"
      title="Log de Classificação"
      scrollable
      ok-only
    >
      <app-timeline>
        <app-timeline-item
          v-for="item in rend"
          :key="item.id"
          :variant="item.classificação === 'AGUARDANDO ANALISE DA INSTITUIÇÃO' ? 'success' : item.classificação === 'ANALISE URGENTE'? 'warning' : item.classificação === 'PROPOSTA EM ANALISE DA SUPERVISÃO' ? 'secondary' : 'info'"
        >
          <div>
            <h6>
              Log {{ item.id }}
            </h6>

            <b-list-group
              flush
              class="mt-1"
            >
              <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span><strong>Classificação: </strong>{{ item.classificação }}</span>
              </b-list-group-item>
              <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span><strong>Data: </strong>{{ item.data_criação }}</span>
              </b-list-group-item>
              <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span><strong>Atualização: </strong>{{ item.data_atualização }}</span>
              </b-list-group-item>
              <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span><strong>Nome usuário: </strong>{{ item.nome_usuario }}</span>
              </b-list-group-item>

              <br>
            </b-list-group>

          </div>
        </app-timeline-item>
      </app-timeline>
    </b-modal>

    <!-- Modal log Classificação-->

    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      align="center"
      size="sm"
    />

  </div>
</template>

<script>
import {
  BTable, BRow, BCol, BPagination, BButton, BBadge, BListGroup, BListGroupItem, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { saveAs } from 'file-saver'
import axios from '@/../axios-auth'

const ExcelJS = require('exceljs')

export default {
  components: {
    BTable,
    BBadge,
    BButton,
    BRow,
    BCol,
    vSelect,
    BPagination,
    AppTimeline,
    AppTimelineItem,
    BListGroup,
    BListGroupItem,
    BAlert,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      totalRows: 1,
      perPage: 15,
      clientStatusFilter: [],
      credoresFilter: [],
      itemsClients: [],
      sortBy: 'updated_at',
      sortDesc: true,
      fields: [
        {
          key: 'id',
        },
        {
          key: 'nome',
        },
        {
          key: 'status_cliente_id', label: 'classificação cliente',
        },
        {
          key: 'nome_credor', label: 'nome credor',
        },
        {
          key: 'localizacao', label: 'status localização',
        },
        {
          key: 'created_at', label: 'data classificação',
        },
        {
          key: 'ultimo_contato',
        },
        {
          key: 'visualizar', class: 'text-center',
        },

      ],
      rend: {
        created_at: '',
        id: '',
        classificação: '',
        updated_at: '',
        user_id: '',
        usuario: '',
      },
      totalClassificacaoCliente: 1,
    }
  },

  computed: {
    optionClassification() {
      const { items } = this
      const classification = []

      items.map(item => {
        item.status_cliente_id ? classification.push(item.status_cliente_id) : null
      })

      const itemsFiltered = [...new Set(classification)]

      const itemsFilteredFormated = []
      itemsFiltered.map(itemToFormat => {
        itemsFilteredFormated.push(JSON.parse(itemToFormat).title)
      })

      return itemsFilteredFormated
    },
    optionsCredores() {
      const { items } = this
      const credores = []

      items.map(item => {
        item.nome_credor ? credores.push(item.nome_credor) : null
      })

      const itemsFiltered = [...new Set(credores)]
      return itemsFiltered
    },

    listTableFiltered() {
      const { items, clientStatusFilter: csf, credoresFilter: credor } = this

      let itemsList = items

      if (csf.length > 0 || credor.length > 0) {
        itemsList = []

        for (let i = 0; i <= csf.length - 1; i++) {
          // eslint-disable-next-line no-loop-func
          items.map(item => {
            if (JSON.parse(item.status_cliente_id).title === csf[i]) {
              itemsList.push(item)
            }
          })
        }

        for (let i = 0; i <= credor.length - 1; i++) {
          let newFilter = []
          itemsList.length > 0 ? (newFilter = itemsList) : (newFilter = items)
          const filterList = []
          newFilter.map(item => {
            if (item.nome_credor === credor[i]) {
              filterList.push(item)
            }
          })
          itemsList = filterList
        }
      }
      this.totalRows = itemsList.length
      this.totalClassificacaoCliente = itemsList.length
      return itemsList
    },

  },

  watch: {
  },

  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    async exportXlsx() {
      const file = await this.getXlsx()
      if (file) {
        saveAs(file)
      }
    },

    async getXlsx() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Classificação de clientes')
      const itemsTable = this.listTableFiltered

      worksheet.columns = [
        { header: 'ID' },
        { header: 'nome' },
        { header: 'cpf/cnpj' },
        { header: 'endereço' },
        { header: 'bairro' },
        { header: 'classificação cliente' },
        { header: 'status localização' },
        { header: 'data classificação' },
        { header: 'data atualização' },
        { header: 'numero' },
        { header: 'observações' },
        { header: 'código' },
        { header: 'último contato' },
        { header: 'data conficação cadastro' },
        { header: 'cidade' },
        { header: 'uf' },
      ]

      itemsTable.map(item => {
        worksheet.addRow([
          item.id,
          item.nome,
          item.cpf_cnpj,
          item.endereco,
          item.bairro,
          JSON.parse(item.status_cliente_id).title,
          item.localizacao,
          this.formatTimezone(item.created_at),
          item.updated_at ? this.formatTimezone(item.updated_at) : '',
          item.numero,
          item.observacoes,
          item.codigo,
          item.ultimo_contato ? this.formatTimezone(item.ultimo_contato) : '',
          item.data_cadastro_confirmado ? this.formatTimezone(item.data_cadastro_confirmado) : '',
          item.cidade,
          item.uf,
        ])
      })

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })

      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], 'Relatório de classificação de clientes', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },

    async toChangeView(item) {
      this.$router.push({ name: 'negociacao-cliente', params: { id: item.id } })
    },

    logClassificacao(rowItem) {
      const body = {
        id_cliente: rowItem.cliente_id,
      }
      axios.post('api/v1/log/cliente/filtro', body, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }).then(res => {
        const data = res.data.dados
        this.rend = data.map(item => ({
          data_criação: this.dataHora(item.created_at, true),
          id: item.id,
          classificação: item.classificacao_cliente,
          data_atualização: this.dataHora(item.updated_at, true),
          user_id: item.user_id,
          nome_usuario: item.usuario,
        }))
      })

      this.$refs.viewDataLog.show()
    },

  },
}
</script>

<style>

</style>
