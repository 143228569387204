<template>
  <div>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="items"
    >
      <template #cell(created_at)="data">
        {{ data.item.created_at ? dataHora(data.item.created_at, true) : ' ' }}
      </template>
      <template #cell(executivo)="data">
        {{ fisrtLastName(data.item.executivo) }}
      </template>
      <template #cell(acoes)="data">
        <b-row align-h="center">
          <b-col cols="auto">
            <feather-icon
              icon="NavigationIcon"
              class="color-icon mr-1 cursor-pointer"
              @click="setCliente(data.item)"
            />
          </b-col>
        </b-row>
      </template>
    </b-table>
  </div>
</template>
<script>
import { BTable } from 'bootstrap-vue'

export default {
  components: {
    BTable,
  },
  props: {
    itemsbaixa: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      fields: [
        { key: 'created_at', label: 'DATA ACORDO', class: 'text-center' },
        { key: 'numero_acordo', label: 'NÚMERO ACORDO', class: 'text-center' },
        { key: 'credor', label: 'CREDOR', class: 'text-center' },
        { key: 'cliente', label: 'CLIENTE', class: 'text-center' },
        { key: 'executivo', label: 'EXECUTIVO', class: 'text-center' },
        { key: 'situacao', label: 'SITUAÇÃO', class: 'text-center' },
        { key: 'valor_nominal', label: 'VALOR ACORDO', class: 'text-center' },
        { key: 'status', label: 'STATUS', class: 'text-center' },
        { key: 'quantidade_parcelas', label: 'QNT. PARCELAS', class: 'text-center' },
        { key: 'acoes', label: 'AÇÕES', class: 'text-center' },
      ],
    }
  },
  created() {
    this.items = this.itemsbaixa
  },
  methods: {
    async setCliente(item) {
      this.$router.push({ name: 'negociacao-cliente', params: { id: item.cliente_id } })
    },
  },
}
</script>
