<template>
  <div>
    <b-overlay
      :show="load"
      variant="transparent"
      opacity="1.0"
    >
      <b-row>
        <b-col
          md="2"
        >
          <b-form-group
            label="CLIENTE ID"
            label-for="op.cliente_id"
          >
            <b-form-input
              id="op.cliente_id"
              :value="op.cliente_id"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          md="10"
        >
          <b-form-group
            label="Nome"
            label-for="op.cliente_nome"
          >
            <b-form-input
              id="op.cliente_nome"
              :value="op.cliente"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          md="10"
        >
          <b-form-group
            v-if="!edit"
            label="Aluno"
            label-for="aluno"
          >
            <b-form-input
              id="op.aluno"
              :value="op.aluno"
              readonly
            />
          </b-form-group>
          <b-form-group v-else>
            <b-form-group
              label="Aluno"
              label-for="aluno"
            >
              <b-form-input
                v-model="editOperacao.aluno"
              />
            </b-form-group>
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col
          md="2"
        >
          <b-form-group
            label="OPERACAO ID"
            label-for="op_id"
          >
            <b-form-input
              id="op_id"
              :value="op.id"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          md="10"
        >
          <b-form-group
            v-if="!edit"
            label="DESCRIÇÃO"
            label-for="op_descricao"
          >
            <b-form-input
              id="op_descricao"
              :value="op.descricao"
              readonly
            />
          </b-form-group>
          <b-form-group
            v-else
            label="DESCRIÇÃO"
            label-for="descricao"
          >
            <b-form-input
              v-model="editOperacao.descricao"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-form-group
            v-if="!edit"
            label="COMPETENCIA"
            label-for="competencia"
          >
            <b-form-input
              id="op_competencia"
              :value="op.competencia"
              readonly
            />
          </b-form-group>
          <b-form-group
            v-else
            label="COMPETENCIA"
            label-for="competencia"
          >
            <b-form-input
              v-model="editOperacao.competencia"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="3"
        >
          <b-form-group
            label="NUMERO OPERAÇÃO"
            label-for="op_num"
          >
            <b-form-input
              id="op_num"
              :value="op.numero_operacao"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          md="3"
        >
          <b-form-group
            label="STATUS OPERAÇÃO"
            label-for="op_status"
          >
            <b-form-input
              id="op_status"
              :value="op.status"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          md="3"
        >
          <b-form-group
            v-if="!edit"
            label="DATA VENCIMENTO"
            label-for="op_date_vencimento"
          >
            <b-form-input
              id="op_date_vencimento"
              :value="formatTimezone(op.data_vencimento)"
              readonly
            />
          </b-form-group>
          <b-form-group
            v-else
            label="DATA VENCIMENTO"
          >
            <b-form-input
              v-model="editOperacao.data_vencimento"
              locale="pt"
              type="date"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="3"
        >
          <b-form-group
            label="DATA PROCESSAMENTO"
            label-for="op_data_processamento"
          >
            <b-form-input
              id="op_data_processamento"
              :value="op.data_processamento"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="6"
          md="4"
        >
          <b-form-group
            v-if="!edit"
            label="VALOR NOMINAL"
            label-for="op_valor_nominal"
          >
            <b-form-input
              id="op_valor_nominal"
              :value="op.valor_nominal"
              readonly
            />
          </b-form-group>
          <b-form-group
            v-else
            label="VALOR NOMINAL"
          >
            <money
              v-model="editOperacao.valor_nominal"
              v-bind="money"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="4"
        >
          <b-form-group
            label="VALOR ATUALIZADO"
            label-for="op_valor_atualizado"
          >
            <b-form-input
              id="op_valor_atualizado"
              :value="op.valor_atualizado"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="4"
        >
          <b-form-group
            label="DESCONTO"
            label-for="op_valor_desconto"
          >
            <b-form-input
              id="op_valor_desconto"
              :value="op.desconto"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-card
          v-if="op.adicionais"
          title="Adicionais"
        >
          <div>
            <label class="ml-1">ADICIONAIS</label>
            <b-row
              v-for="item in Object.keys(op.adicionais)"
              :key="item"
            >
              <b-col
                cols="12"
                class="mb-1 ml-1"
              >
                {{ item.replace('_', ' ') }}:
                <b-form-input
                  id="op.adicionais"
                  :value="op.adicionais[item]"
                  readonly
                />
              </b-col>
            </b-row>
          </div>
        </b-card>
        <b-col v-if="!edit">
          <div v-show="isExctivo">
            <b-button
              variant="primary"
              @click="setEdit"
            >
              Editar
            </b-button>
          </div>
        </b-col>
        <b-col v-else>
          <b-button
            class="mr-3"
            variant="success"
            @click="submit"
          >
            Salvar
          </b-button>
          <b-button
            variant="danger"
            @click="reset"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>
<script>

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BOverlay,
  BCard,
} from 'bootstrap-vue'
import { Money } from 'v-money'
import axios from '@/../axios-auth'

export default {
  components: {
    Money,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    BCard,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    idOp: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      op: {
        id: '',
        aluno: '',
        numero_operacao: '',
        data_vencimento: '',
        data_processamento: '',
        valor_nominal: '',
        valor_atualizado: '',
        descricao: '',
        desconto: '',
        adicionais: '',
        cliente_id: '',
        cliente: '',
        cliente_endereco: '',
        cliente_bairro: '',
        status: '',
        competencia: '',
      },
      edit: null,
      editOperacao: {
        aluno: '',
        data_vencimento: '',
        valor_nominal: '',
        descricao: '',
        competencia: '',
        numero_operacao: '',
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
      load: true,
    }
  },
  computed: {
    isExctivo() {
      return localStorage.getItem('funcao') !== 'Executivo de Cobrança'
    },
  },
  async mounted() {
    await this.getInfoOperacao()
  },
  methods: {
    reset() {
      this.edit = false
    },
    setEdit() {
      this.editOperacao.aluno = this.op.aluno
      this.editOperacao.data_vencimento = this.op.data_vencimento
      this.editOperacao.valor_nominal = this.op.valor_nominal
      this.editOperacao.descricao = this.op.descricao
      this.editOperacao.competencia = this.op.competencia
      this.editOperacao.numero_operacao = this.op.numero_operacao
      this.edit = true
    },
    submit() {
      this.load = true
      axios.put(`api/v1/operacoes/update/${this.idOp}`, this.editOperacao)
        .then(() => {
          this.getInfoOperacao()
          this.edit = false
          this.$swal({
            title: 'Operação editada com sucesso!',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(error => {
          if (error.response) {
            this.$swal({
              title: 'ERROR!',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                comfirmButton: 'btn btn-danger',
              },
            })
          }
        })
    },
    extrairData(date) {
      return date.split('T')[0]
    },
    async getInfoOperacao() {
      await axios.get(`api/v1/operacoes/edit/${this.idOp}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(async res => {
        this.load = false
        const dt = res.data.dados.operacao

        // DADOS OPEACAO
        this.op.id = dt.id
        this.op.numero_operacao = dt.numero_operacao
        // this.op.cliente = dt.nome_cliente
        this.op.data_vencimento = this.extrairData(dt.data_vencimento)
        this.op.data_processamento = this.dataHora(dt.data_processamento, true)
        this.op.valor_nominal = this.valorBr(dt.valor_nominal, true)
        this.op.valor_atualizado = this.valorBr(dt.valor_atualizado, true)
        this.op.descricao = dt.descricao
        this.op.desconto = dt.desconto
        // this.op.pendencia = dt.nome_pendencia
        this.op.status = dt.status_operacao.nome
        this.op.competencia = dt.competencia

        // INFO CLIENTE
        this.op.cliente_id = dt.cliente.id
        this.op.cliente = dt.cliente.nome
        this.op.aluno = dt.aluno
        this.op.adicionais = JSON.parse(dt.adicionais)
        // this.op.cliente_cpf_cnpj = dt.cliente.cpf_cnpj
        this.op.cliente_endereco = dt.cliente.endereco
        this.op.cliente_bairro = dt.cliente.bairro
        // this.op.cliente_cep = dt.cliente.cep
      })
    },
  },
}
</script>
