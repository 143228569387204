<template>
  <div>
    <!--Modal para filtros-->
    <b-modal
      id="filtro"
      ok-title="Filtrar"
      ok-only
      @ok="
        setFilter(),
        showOverlay = true,
        showClearDate = true
      "
    >
      <b-row
        align-h="around"
        class="mb-2 mt-2"
      >
        <b-col cols="start">
          <b-row align-h="center">
            <h4>Inicio</h4>
          </b-row>
          <b-row>
            <b-form-input
              v-model="dueDate.start"
              locale="pt"
              type="date"
            />
          </b-row>
        </b-col>
        <b-col cols="end">
          <b-row align-h="center">
            <h4>Fim</h4>
          </b-row>
          <b-row>
            <b-form-input
              v-model="dueDate.end"
              locale="pt"
              type="date"
            />
          </b-row>
        </b-col>

      </b-row>
      <b-col
        v-if="!checked"
        cols="center"
      >
        <b-row align-h="center">
          <h4>Status</h4>
        </b-row>
        <b-row>
          <b-col
            cols="10"
            class="ml-3"
          >
            <v-select
              v-model="modelFilter"
              :options="optionsFilter"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-modal>
    <!--Modal para filtros-->

    <b-overlay
      :show="showOverlay"
      no-wrap
    />

    <b-row
      align-h="between"
      class="mb-1"
    >
      <b-col
        cols="auto"
      >
        <b-button
          v-b-modal.filtro
          class="bg-info bg-darken-2"
        >
          Filtro
        </b-button>

        <b-button
          v-show="showClearDate"
          class="ml-2"
          variant="flat-primary"
          @click="clearDateFilter"
        >
          Limpar
        </b-button>
      </b-col>

      <b-col
        cols="auto"
      >
        <b-alert
          variant="danger"
          show
        >
          <h4 class="alert-heading">
            Pendências: {{ checked ? totalRowsConcluidas : totalFiltroPendencia }}
          </h4>
        </b-alert>
      </b-col>

      <b-col
        cols="auto"
      >
        <b-button
          v-show="selected.length > 0"
          v-ripple.400="'rgba(255, 159, 67, 0.15)'"
          class="mr-2"
          :disabled="conditionals() === false || finishedStatus() === false"
          variant="outline-warning"
          @click="$refs.sendStatus.show()"
        >
          <feather-icon
            icon="EditIcon"
            class="mr-50"
          />
          {{
            conditionals() === false ? 'Status diferentes!' :
            finishedStatus() === false ? 'Já encontra-se concluido' : 'Tratar Pendência'
          }}
        </b-button>

        <b-button
          v-if="selected <= 0"
          variant="outline-warning"
          @click="exportXlsx"
        >
          <feather-icon
            icon="DownloadIcon"
            class="mr-50"
          />
          Exportar excel
        </b-button>

        <b-button
          v-else
          variant="outline-info"
          @click="exportXlsxSelected"
        >
          <feather-icon
            icon="DownloadIcon"
            class="mr-50"
          />
          Exportar seleção
        </b-button>

      </b-col>

      <!-- Exportar dados-->
      <!-- Exportar dados-->

    </b-row>
    <b-form-checkbox
      v-model="checked"
      class="mb-2"
      @change="getPendenciasConcluidas"
    >
      <span class="vs-checkbox">
        <span
          class="vs-checkbox--check"
        />
      </span>
      <h4>Mostrar Pendências Concluidas</h4>
    </b-form-checkbox>
    <b-row
      class="mb-2"
    >
      <b-col
        md="2"
      >
        <label>Filtro credores</label>
        <v-select
          v-model="credorFilter"
          :options="optionsCredor"
          multiple
        />
      </b-col>

      <b-col
        md="2"
      >
        <label>Filtro pendencias</label>
        <v-select
          v-model="pendenciasFilter"
          :options="optionsPendencias"
          multiple
        />
      </b-col>

      <b-col
        v-show="!checked"
        md="2"
      >
        <label>Filtro status</label>
        <v-select
          v-model="statusFilter"
          :options="optionsStatus"
          multiple
        />
      </b-col>

      <b-col
        md="5"
        align-self="end"
      >
        <b-form-input
          v-model="filter"
          type="search"
          placeholder="Pesquisar"
          size="bg"
          debounce="800"
        />
      </b-col>
      <b-col
        md="1"
        align-self="end"
      >
        <b-form-select
          v-model="perPage"
          :options="[10, 15, 20]"
          size="bg"
        />
      </b-col>

    </b-row>
    <b-overlay
      :show="load"
      variant="transparent"
    >
      <b-table
        small
        striped
        responsive
        :filter="filter"
        :fields="checked ? fieldsConcluidas : fields"
        :items="checked ? newListConcluida : newListFiltered"
        :per-page="perPage"
        :current-page="currentPage"
        @filtered="onFiltered"
        @row-clicked="rowClicked"
      >
        <!--Seleciona todos-->
        <template #head(check)>
          <b-form-checkbox
            v-show="!checked"
            v-model="selectAll"
          />
        </template>

        <!--status-->
        <template
          #cell(status_pendencia)="data"
        >
          <b-badge
            :variant="data.item.status_pendencia === 'CONCLUIDO' ? 'light-success' :
              data.item.status_pendencia === 'EM ANDAMENTO'? 'light-warning' :
              data.item.status_pendencia === 'REGISTRO' ? 'light-secondary' :
              data.item.status_pendencia === 'REENVIADO' ? 'light-primary' : 'light-info'"
          >
            {{ data.item.status_pendencia }}
          </b-badge>
        </template>
        <!--status-->

        <!--seleciona a linha-->
        <template
          #cell(check)="row"
        >
          <b-form-checkbox
            v-show="!checked"
            v-model="selected"
            :value="row.item"
          />
        </template>
        <template #cell(vizualizar)="row">
          <feather-icon
            variant="primary"
            icon="EyeIcon"
            class="cursor-pointer"
            @click="logPendencias(row)"
          />
        </template>

        <template #cell(data_vencimento)="data">
          {{ formatTimezone(data.item.data_vencimento) }}
        </template>
        <template #cell(valor_nominal)="data">
          {{ valorBr(data.item.valor_nominal, true) }}
        </template>
        <template
          v-if="checked"
          #cell(data_conclusao)="data"
        >
          {{ formatTimezone(data.item.data_conclusao) }}
        </template>
        <template
          v-if="checked"
          #cell(usuario)="data"
        >
          {{ fisrtLastName(data.item.usuario) }}
        </template>
      <!-- <template #cell(desconto)="data">
        {{ valorBr(data.item.desconto, true) }}
      </template> -->
      </b-table>
    </b-overlay>
    <b-pagination
      v-model="currentPage"
      :total-rows="checked ? totalRowsConcluidas: totalRows"
      :per-page="perPage"
      align="center"
      size="sm"
    />
    <b-modal
      ref="modalOperacao"
      hide-footer
      size="xl"
    >
      <info-Operacao
        :id-op="idOp"
      />
    </b-modal>

    <!--Modal de envio de dados para o back-->
    <b-modal
      ref="sendStatus"
      title="Tratar pendencias"
      ok-only
      no-close-on-backdrop
      @hide="clearModal"
      @ok="updatedPendencia(cardData.observacao, cardData.status, cardData.operacao)"
    >
      <b-row>
        <!-- <b-col
          v-show="showOper === true"
          cols="12"
          class="mt-2"
        >
          Operações:
          <v-select
            v-model="selected"
            multiple
          />
        </b-col> -->
        <b-col
          cols="12"
          class="mt-2"
        >
          Status:
          <v-select
            v-model="cardData.status"
            :options="option"
          />

        </b-col>
        <b-col
          cols="12"
          class="mt-2"
        >
          Descrição:
          <b-form-textarea
            v-model="cardData.observacao"
            required
          />
        </b-col>
        <b-col
          v-show="cardData.status === 'CONCLUIDO'"
          cols="12"
          class="mt-2"
        >
          Motivo Operação:
          <v-select
            v-model="cardData.operacao"
            :options="optionsOperacaoMotivo"
          />
        </b-col>
      </b-row>
    </b-modal>
    <!--Modal de envio de dados para o back-->

    <!--Modal de vizualização dos logs-->
    <b-modal
      ref="viewDataPendencies"
      title="Log de pendencias"
      ok-only
    >
      <app-timeline>
        <app-timeline-item
          v-for="item in rend"
          :key="item.id"
          :variant="item.status === 'CONCLUIDO' ? 'success' : item.status === 'EM ANDAMENTO'? 'warning' : item.status === 'REGISTRO' ? 'secondary' : item.status === 'REENVIADO' ? 'primary' : 'info'"
        >
          <div>
            <h6>
              {{ item.numero_operacao }}
            </h6>

            <b-list-group
              flush
              class="mt-1"
            >
              <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span><strong>Observações: </strong>{{ item.observação }}</span>
              </b-list-group-item>
              <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span><strong>Status: </strong>{{ item.status }}</span>
              </b-list-group-item>
              <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span><strong>Data: </strong>{{ item.data_criação }}</span>
              </b-list-group-item>
              <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span><strong>Atualização: </strong>{{ item.data_atualização }}</span>
              </b-list-group-item>
              <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span><strong>Nome usuário: </strong>{{ item.nome_usuario }}</span>
              </b-list-group-item>
              <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span><strong>Motivo pendencia: </strong>{{ item.motivo_pendencia }}</span>
              </b-list-group-item>

              <br>
            </b-list-group>

          </div>
        </app-timeline-item>
      </app-timeline>
      <!--Timelime modal-->
      <!-- <b-row>
          <b-col>
            <div
              v-for="key in rend"
              :key="key"
            >
              <div
                v-for="item in Object.keys(key)"
                :key="item.id"
              >
                <span><strong>{{ item }}: </strong>{{ key[item] }}</span>
              </div>
              <hr>

            </div>
          </b-col>
        </b-row> -->
    </b-modal>
    <!--Modal de vizualização dos logs-->

  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BTable,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BFormTextarea,
  BPagination,
  BModal,
  BButton,
  VBToggle,
  VBTooltip,
  BBadge,
  BOverlay,
  BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line no-unused-vars
import { saveAs } from 'file-saver'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import axios from '@/../axios-auth'
import infoOperacao from '@/views/backoffice/operacao/cards/OperacoesInfo.vue'

const ExcelJS = require('exceljs')

export default {
  components: {
    BOverlay,
    AppTimeline,
    AppTimelineItem,
    BBadge,
    BRow,
    BCol,
    BTable,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BFormTextarea,
    BPagination,
    BModal,
    BButton,
    infoOperacao,
    vSelect,
    BAlert,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  props: {
    items: {
      type: Array,
      required: true,
    },
    updateTable: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      showClearDate: false,
      showOverlay: false,
      dueDate: {
        start: '',
        end: '',
      },
      rend: {
        idd: '',
        numero_operacao: '',
        operacao_id: '',
        usuario_empresa_id: '',
        observacao: '',
        status: '',
        created_at: '',
        updated_at: '',
        nome_usuario: '',
        motivo_pendencia: '',
      },
      cardData: {
        operacoes_id: [],
        observacao: '',
        status: '',
        operacao: '',
      },
      option: [
        'INICIADO',
        'EM ANDAMENTO',
        'REENVIADO',
        'CONCLUIDO',
      ],
      optionsCaseRegister: [
        'INICIADO',
      ],
      optionsCaseRegisterMarket: [
        'INICIADO',
      ],
      itemsSelectPendencies: [],

      // news
      showOper: false,
      selectAll: false,
      verifyUpdateStatusCell: false,
      selected: [],
      credorFilter: [],
      itemsToFilter: [],
      pendenciasFilter: [],
      statusFilter: [],
      // eslint-disable-next-line vue/no-dupe-keys
      items: [],
      // news
      opSelect: [],
      mtPendSelect: 'oi',
      description: '',
      mtPend: {
        select: '',
        options: [
          {
            label: 'REMOVER PENDENCIA',
            id: null,
          },
        ],
      },
      idOp: '',
      currentPage: 1,
      totalRows: 1,
      perPage: 15,
      filter: '',
      fields: [
        {
          key: 'check',
        },
        {
          key: 'status_pendencia', sortable: true,
        },
        {
          key: 'credor', sortable: true,
        },
        {
          key: 'cliente', sortable: true,
        },
        {
          key: 'cpf_cnpj', sortable: true,
        },
        {
          key: 'aluno', sortable: true,
        },
        {
          key: 'numero_operacao', sortable: true,
        },
        {
          key: 'data_vencimento', sortable: true,
        },
        {
          key: 'motivo_pendencia', sortable: true,
        },
        {
          key: 'valor_nominal', class: 'text-center', sortable: true,
        },
        {
          key: 'vizualizar', class: 'text-center',
        },
      ],
      itemsPendencia: [],
      totalFiltroPendencia: 0,
      optionsOperacaoMotivo: [
        'DEVOLUÇÃO',
        'BAIXA ADMINSTRATIVA',
        'RETORNO PARA COBRANÇA',
      ],
      load: false,
      modelFilter: [],
      optionsFilter: [
        'REGISTRO',
        'INICIADO',
        'EM ANDAMENTO',
        'REENVIADO',
      ],
      // tabela de concluidos
      checked: false,
      pendenciasConcluidas: [],
      fieldsConcluidas: [
        {
          key: 'check',
        },
        {
          key: 'status_pendencia',
        },
        {
          key: 'credor',
        },
        {
          key: 'cliente',
        },
        {
          key: 'cpf_cnpj',
        },
        {
          key: 'aluno',
        },
        {
          key: 'numero_operacao',
        },
        {
          key: 'data_vencimento',
        },
        {
          key: 'data_conclusao',
        },
        {
          key: 'motivo_pendencia',
        },
        {
          key: 'usuario',
        },
        {
          key: 'valor_nominal', class: 'text-center',
        },
        {
          key: 'vizualizar', class: 'text-center',
        },
      ],
      totalRowsConcluidas: 1,
    }
  },
  computed: {
    optionsCredor() {
      const { items } = this
      const itemsConcluidos = this.pendenciasConcluidas
      const credores = []
      let filtered = []

      if (this.checked) {
        itemsConcluidos.map(item => {
          item.credor ? credores.push(item.credor) : null
        })

        filtered = [...new Set(credores)]
      } else {
        items.map(item => {
          item.credor ? credores.push(item.credor) : null
        })

        filtered = [...new Set(credores)]
      }
      return filtered
    },
    // 460795

    optionsPendencias() {
      const { items } = this
      const itemsConcluidos = this.pendenciasConcluidas
      const pendencias = []
      let pendenciesFiltered = []

      if (this.checked) {
        itemsConcluidos.map(item => {
          item.motivo_pendencia ? pendencias.push(item.motivo_pendencia) : null
        })

        pendenciesFiltered = [...new Set(pendencias)]
      } else {
        items.map(item => {
          item.motivo_pendencia ? pendencias.push(item.motivo_pendencia) : null
        })

        pendenciesFiltered = [...new Set(pendencias)]
      }
      return pendenciesFiltered
    },

    optionsStatus() {
      const { items } = this
      const status = []

      items.map(item => {
        item.status_pendencia ? status.push(item.status_pendencia) : null
      })
      const statusFiltered = [...new Set(status)]
      return statusFiltered
    },

    newListFiltered() {
      const {
        items, credorFilter: cf, pendenciasFilter: pf, statusFilter: sf,
      } = this
      let list = items

      if (cf.length > 0 || pf.length > 0 || sf.length > 0) {
        list = []

        // Filtro por credores
        let newFilterCred = []
        const filterListCred = []
        list.length > 0 ? (newFilterCred = list) : (newFilterCred = items)
        for (let i = 0; i <= cf.length - 1; i++) {
          // eslint-disable-next-line no-loop-func
          newFilterCred.map(item => {
            if (item.credor === cf[i]) {
              filterListCred.push(item)
            }
          })
          list = filterListCred
        }

        // Filtro por pendencia
        let newFilter = []
        const filterList = []
        list.length > 0 ? (newFilter = list) : (newFilter = items)
        for (let i = 0; i <= pf.length - 1; i++) {
          newFilter.map(item => {
            if (item.motivo_pendencia === pf[i]) {
              filterList.push(item)
            }
          })
          list = filterList
        }

        // Filtro por status
        let newFilterStatus = []
        const filterListStatus = []
        list.length > 0 ? (newFilterStatus = list) : (newFilterStatus = items)
        for (let i = 0; i <= sf.length - 1; i++) {
          newFilterStatus.map(item => {
            if (item.status_pendencia === sf[i]) {
              filterListStatus.push(item)
            }
          })
          list = filterListStatus
        }
      }
      this.totalRows = list.length
      this.totalFiltroPendencia = list.length
      return list
    },
    newListConcluida() {
      const {
        credorFilter: cf, pendenciasFilter: pf,
      } = this
      const items = this.pendenciasConcluidas
      let list = this.pendenciasConcluidas

      if (cf.length > 0 || pf.length > 0) {
        list = []

        // Filtro por credores
        let newFilterCred = []
        const filterListCred = []
        list.length > 0 ? (newFilterCred = list) : (newFilterCred = items)
        for (let i = 0; i <= cf.length - 1; i++) {
          // eslint-disable-next-line no-loop-func
          newFilterCred.map(item => {
            if (item.credor === cf[i]) {
              filterListCred.push(item)
            }
          })
          list = filterListCred
        }

        // Filtro por pendencia
        let newFilter = []
        const filterList = []
        list.length > 0 ? (newFilter = list) : (newFilter = items)
        for (let i = 0; i <= pf.length - 1; i++) {
          newFilter.map(item => {
            if (item.motivo_pendencia === pf[i]) {
              filterList.push(item)
            }
          })
          list = filterList
        }
      }
      this.totalRowsConcluidas = list.length
      return list
    },
  },
  watch: {
    selectAll(n) {
      n ? this.selecionarTodos() : this.limparSelecao()
    },
    // verifyUpdateStatusCell(up) {
    //   up ? this.modifyStatusCell() : null
    // },
  },

  async mounted() {
    this.getMtPend()
    this.itemsPendencia = this.items

    // this.totalRows = this.items.length
  },
  methods: {
    async clearDateFilter() {
      axios.get('api/v1/operacoes/pendencias', {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      }).then(async res => {
        this.items = res.data.dados
        this.showClearDate = false
        // this.limparSelecao()
      })
    },
    // realiza a busca do filtro mencionado no modal
    async setFilter() {
      const filter = {}

      if (this.dueDate.start) {
        filter.data_inicio = this.dueDate.start
      }
      if (this.dueDate.end) {
        filter.data_final = this.dueDate.end
      }
      if (this.modelFilter.length > 0) {
        filter.status_pendencia = [this.modelFilter]
      }

      if (Object.keys(filter).length < 2) {
        this.$swal({
          title: 'Error!',
          text: 'Selecione as duas datas para realizar a filtragem',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      } else if (this.checked) {
        await axios.post('api/v1/operacoes/filtro/pendencias_concluidas', filter, {
          headers: {
            Accept: 'Application/json',
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          },
        }).then(res => {
          this.pendenciasConcluidas = res.data
          this.showOverlay = false
          this.limparSelecao()
        })
      } else {
        await axios.post('api/v1/operacoes/filtro/pendencias', filter, {
          headers: {
            Accept: 'Application/json',
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          },
        }).then(res => {
          this.items = res.data
          this.showOverlay = false
          this.limparSelecao()
        })
      }
    },
    updateGrid() {
      this.load = true
      axios.get('api/v1/operacoes/pendencias', {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      }).then(async res => {
        this.load = false
        this.items = res.data.dados
        this.limparSelecao()
      })
    },
    // modifyStatusCell() {
    //   // this.items[0].status = this.cardData.status

    //   // const { items } = this
    //   this.items.map((item, index) => {
    //     this.selected.map(select => {
    //       select.operacoes_id === item.operacoes_id ? this.items[index].status === this.cardData.status : null
    //     })
    //   })
    // },
    conditionals() {
      const status = []
      this.selected.map(item => {
        status.push(item.status)
      })
      const verify = [...new Set(status)]
      if (verify.length > 1) {
        return false
      }
      return true
    },

    finishedStatus() {
      const finished = []
      this.selected.map(item => {
        item.status === 'CONCLUIDO' ? finished.push(item.status) : null
      })

      const verify = [...new Set(finished)]

      if (verify[0] === 'CONCLUIDO') {
        return false
      }
      return true
    },

    optionsRegister() {
      const initial = []
      this.selected.map(item => {
        initial.push(item.status)
      })
      const verify = [...new Set(initial)]
      if (verify[0] === 'REGISTRO') {
        return true
      }
      return false
    },
    clearModal() {
      this.cardData = {
        operacoes_id: '',
        observacao: '',
        status: '',
      }
    },

    async exportXlsx() {
      const file = await this.getXlsx()
      if (file) {
        saveAs(file)
      }
    },

    async exportXlsxSelected() {
      const file = await this.getXlsxselection()
      if (file) {
        saveAs(file)
      }
    },

    async getXlsx() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Pendencias')
      const itemsTable = this.checked ? this.newListConcluida : this.newListFiltered

      itemsTable.sort((a, b) => {
        const x = a.aluno.toUpperCase()
        const y = b.aluno.toUpperCase()

        // eslint-disable-next-line no-nested-ternary
        return x === y ? 0 : x > y ? 1 : -1
      })

      worksheet.columns = [
        { header: 'Status' },
        { header: 'Credor' },
        { header: 'Cliente' },
        { header: 'Cpf/Cnpj' },
        { header: 'Aluno(a)' },
        { header: 'Numero operação' },
        { header: 'Data vencimento' },
        { header: 'Valor nominal' },
        { header: 'Motivo pendência' },
        { header: 'Posicionamento da Instituição' },
      ]

      itemsTable.map(itemRow => {
        const values = {
          status: '',
          credor: '',
          cliente: '',
          cpf_cnpj: '',
          aluno: '',
          numero_operacao: 0,
          data_vencimento: '',
          valor_nominal: 0,
          motivo_pendencia: '',
        }

        values.status = itemRow.status_pendencia
        values.credor = itemRow.credor
        values.cliente = itemRow.cliente
        values.cpf_cnpj = itemRow.cpf_cnpj
        values.aluno = itemRow.aluno
        values.numero_operacao = itemRow.numero_operacao
        values.data_vencimento = itemRow.data_vencimento
        values.valor_nominal = itemRow.valor_nominal
        values.motivo_pendencia = itemRow.motivo_pendencia

        worksheet.addRow([
          values.status,
          values.credor,
          values.cliente,
          values.cpf_cnpj,
          values.aluno,
          values.numero_operacao,
          this.formatTimezone(values.data_vencimento),
          this.valorBr(parseFloat(values.valor_nominal), true),
          values.motivo_pendencia,
          '',
        ])
      })

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell(cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })

      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell(cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], 'Pendencias', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },

    async getXlsxselection() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Pendencias')
      const itemsTable = this.selected

      worksheet.columns = [
        { header: 'Status' },
        { header: 'Credor' },
        { header: 'Cliente' },
        { header: 'Cpf/Cnpj' },
        { header: 'Aluno' },
        { header: 'Numero operação' },
        { header: 'Data vencimento' },
        { header: 'Valor nominal' },
        { header: 'Motivo pendencia' },
        { header: 'Posicionamento da Instituição' },
      ]

      itemsTable.map(itemRow => {
        const values = {
          status: '',
          credor: '',
          cliente: '',
          cpf_cnpj: '',
          aluno: '',
          numero_operacao: 0,
          data_vencimento: '',
          valor_nominal: 0,
          motivo_pendencia: '',
        }

        values.status = itemRow.status_pendencia
        values.credor = itemRow.credor
        values.cliente = itemRow.cliente
        values.cpf_cnpj = itemRow.cpf_cnpj
        values.aluno = itemRow.aluno
        values.numero_operacao = itemRow.numero_operacao
        values.data_vencimento = itemRow.data_vencimento
        values.valor_nominal = this.valorBr(itemRow.valor_nominal)
        values.motivo_pendencia = itemRow.motivo_pendencia

        worksheet.addRow([
          values.status,
          values.credor,
          values.cliente,
          values.cpf_cnpj,
          values.aluno,
          values.numero_operacao,
          this.formatTimezone(values.data_vencimento),
          this.valorBr(parseFloat(values.valor_nominal)),
          values.motivo_pendencia,
        ])
      })

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell(cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })

      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell(cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], 'Pendencias', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    async getMtPend() {
      await axios.get('api/v1/info/motivopendencia', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(dt => (
          this.mtPend.options.push({
            label: dt.nome,
            id: dt.id,
          })
        ))
      })
    },

    rowClicked(item) {
      this.idOp = item.id
      // this.$refs.modalOperacao.show()
    },

    async updatedPendencia(observacao, status, motivoOperacao) {
      if (status === 'CONCLUIDO') {
        const opId = []
        this.selected.map(item => {
          opId.push(item.operacoes_id)
        })

        // this.cardData.operacoes_id = opId

        await this.sendStatusToBack()

        const body = {
          motivo_pendencia_id: null,
          ops_ids: opId,
          description: observacao,
          motivoId: 21,
          retorno: motivoOperacao,
        }
        await axios.put('api/v1/operacoes/update/lote/motivo/pendencia/', body, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          },
        }).then(() => {
          this.selected = []
          this.$swal({
            title: 'SUCESSO!',
            text: 'Alteração executada com sucesso',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              comfirmButton: 'btn btn-ptimary',
            },
            buttonStyling: false,
          })
        }).catch(() => {
          this.selected = []
          this.$swal({
            title: 'ERROR!',
            text: 'Erro ao executar alteração!',
            icon: 'error',
            customClass: {
              comfirmButton: 'btn btn-danger',
            },
          })
        })
      } else {
        await this.sendStatusToBack()
      }
      // await this.updateGrid()
    },

    async sendStatusToBack() {
      const opId = []
      this.selected.map(item => {
        opId.push(item.operacoes_id)
      })

      this.cardData.operacoes_id = opId

      await axios.post('api/v1/log/pendencias/store', this.cardData, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      }).then(() => {
        this.$swal({
          title: 'Pendencia modificada!',
          icon: 'success',
          text: 'Novo cadastro adicionado!',
          timer: 1500,
          showConfirmButton: false,
        })
      }).catch(() => {
        this.$swal({
          title: 'ERRO!',
          icon: 'error',
          text: 'Ocorreu um erro durante o cadastro!',
          customClass: {
            confimButton: 'btn btn-danger',
          },
        })
      })
      this.updateGrid()
      // Reload da página para alterar o status na tabela
      // this.$router.go(0)
    },

    logPendencias(rowItem) {
      const body = {
        operacao_id: rowItem.item.operacoes_id,
      }
      axios.post('api/v1/operacoes/log/pendencias', body, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }).then(res => {
        const data = res.data.dados
        this.rend = data.map(item => ({
          id: item.id,
          numero_operacao: item.numero_operacao,
          operacao_id: item.operacao_id,
          usuario_id: item.usuario_empresa_id,
          observação: item.observacao,
          status: item.status,
          data_criação: this.dataHora(item.created_at, true),
          data_atualização: this.dataHora(item.updated_at, true),
          nome_usuario: item.nome_usuario,
          motivo_pendencia: rowItem.item.motivo_pendencia,
        }))
      })

      this.$refs.viewDataPendencies.show()
    },

    // created_at: "2022-05-12T18:49:04.000000Z"
    // id: 22
    // observacao: "adqdasdasdfhgjg"
    // operacao_id: 498712
    // status: "INICIADO"
    // updated_at: "2022-05-12T18:49:04.000000Z"
    // usuario_empresa_id: 14
    // async removePendecia() {
    //   const body = {
    //     motivo_pendencia_id: this.mtPendSelect.id,
    //     ops_ids: this.opSelect,
    //     description: this.description,
    //     motivoId: 27,
    //   }
    //   if (this.description.length < 5) {
    //     this.$swal({
    //       title: 'ERRO',
    //       icon: 'error',
    //       text: 'Minimo de 5 carateres na descrição',
    //       confirmButton: {
    //         customClass: 'btn btn-danger',
    //       },
    //     })
    //   } else {
    //     await axios.put('api/v1/operacoes/update/lote/motivo/pendencia/', body, {
    //       headers: {
    //         Authorization: `Bearer ${localStorage.token}`,
    //         accept: 'application/json',
    //       },
    //     }).then(() => {
    //       // this.opSelect = []
    //       this.updateTable()
    //       this.$swal({
    //         title: 'Pendência Removida!',
    //         text: 'Alteração executada com sucesso',
    //         icon: 'success',
    //         showConfirmButton: false,
    //         timer: 1500,
    //         customClass: {
    //           comfirmButton: 'btn btn-ptimary',
    //         },
    //         buttonStyling: false,
    //       })
    //     }).catch(() => {
    //       // this.opSelect = []
    //       this.updateTable()
    //       this.$swal({
    //         title: 'ERROR!',
    //         text: 'Erro ao executar alteração!',
    //         icon: 'error',
    //         customClass: {
    //           comfirmButton: 'btn btn-danger',
    //         },
    //       })
    //     })
    //   }
    // },
    limparSelecao() {
      this.selected = []
    },
    selecionarTodos() {
      this.selected = []

      if (this.filter.length > 0) {
        this.newListFiltered.map(item => {
          if (item.cliente.toLowerCase().includes(this.filter.toLowerCase())
          || item.aluno.toLowerCase().includes(this.filter.toLowerCase())
          || item.cpf_cnpj.trim().includes(this.filter.trim())
          || item.credor.toLowerCase().includes(this.filter.toLowerCase())
          || item.motivo_pendencia.toLowerCase().includes(this.filter.toLowerCase())
          || item.numero_operacao.trim().includes(this.filter.trim())
          || item.valor_nominal.trim().includes(this.filter.trim())
          ) {
            this.selected.push(item)
          }
        })
      } else {
        this.newListFiltered.map(item => {
          this.selected.push(item)
        })
      }
      // this.items[0].status = 'INICIADO'
    },
    getPendenciasConcluidas() {
      if (this.checked) {
        this.load = true
        this.pendenciasConcluidas = []
        axios.get('api/v1/operacoes/pendencias_concluidas', {
          headers: {
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
            accept: 'application/json',
          },
        })
          .then(res => {
            this.load = false
            res.data.dados.map(item => {
              this.pendenciasConcluidas.unshift(item)
            })
          })
      }
    },
  },
}
</script>
