<template>
  <div>
    <b-card class="card-statistics">
      <app-collapse>
        <app-collapse-item
          title="Filtros"
        >
          <div
            class="mb-1 col-md-10"
          >
            <b-row>
              <!-- <label>Forma de recebimento</label>
              <v-select
                id="forma-recebimento"
                :options="statusReceivimentOptions"
                multiple
                class="mb-1"
              />-->
              <label>Credores</label>
              <v-select
                id="forma-recebimento"
                v-model="creditorsFilter"
                multiple
                :options="creditors"
                class="mb-1"
              />
              <div class="row align-items-center">
                <div class="col-md-3">
                  <label for="example-datepicker">Data inicial</label>
                  <b-form-input
                    id="example-datepicker"
                    v-model="date.date_initial"
                    locale="pt"
                    type="date"
                    class="mb-1"
                  />
                </div>

                <div class="col-md-3">
                  <label for="example-datepicker">Data final</label>
                  <b-form-input
                    id="example-datepicker"
                    v-model="date.date_finaly"
                    locale="pt"
                    type="date"
                    class="mb-1"
                  />
                </div>

                <b-col
                  md="4"
                  class="mt-2"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1 mb-1"
                    @click="filter"
                  >
                    Filtrar
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="mb-1"
                    @click="resetFilter"
                  >
                    Resetar filtro
                  </b-button>
                </b-col>
              </div>
            </b-row>
          </div>

        </app-collapse-item>
      </app-collapse>
    </b-card>

    <!--Lista provinda do filtro-->
    <b-card v-show="showListFiltered">
      <b-col class="mb-2">
        <h3>Meu Faturamento</h3>
      </b-col>
      <b-row>
        <b-col md="7">
          <b-row class="mt-1">
            <b-col>
              <div class="d-flex align-items-center mr-2">
                <b-avatar
                  variant="light-success"
                  rounded
                >
                  <feather-icon
                    icon="FileTextIcon"
                    size="20"
                  />
                </b-avatar>
                <div class="ml-1 mr-2">
                  <h5 class="mb-0">
                    {{ valorBr(count.total, true) }}
                  </h5>
                  <small>Parcelas Liquidadas</small>
                </div>
                <b-avatar
                  variant="light-success"
                  rounded
                >
                  <feather-icon
                    icon="DollarSignIcon"
                    size="20"
                  />
                </b-avatar>
                <div class="ml-1 mr-1">
                  <h5 class="mb-0">
                    {{ valorBr(count.liquidado.total, true) }}
                  </h5>
                  <small>Total Remuneração VOZ</small>
                </div>
                <b-avatar
                  variant="light-success"
                  rounded
                >
                  <feather-icon
                    icon="PercentIcon"
                    size="20"
                  />
                </b-avatar>
                <div class="ml-1">
                  <h5 class="mb-0">
                    {{ count.percentual }} %
                  </h5>
                  <small>% Total Remuneração VOZ</small>
                </div>
                <b-row class="ml-2">
                  <b-col>
                    <strong>Quantidade Parcelas</strong>
                  </b-col>
                  <b-col class="text-center">
                    <span>{{ count.parcelas }}</span>
                  </b-col>
                </b-row>
                <b-row class="ml-2">
                  <b-col>
                    <strong>Quantidade Clientes</strong>
                  </b-col>
                  <b-col class="text-center">
                    <span>{{ count.clientes }}</span>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-card
        class="padding-0"
        padding="0"
      >
        <b-button
          variant="outline-warning"
          @click="exportFile"
        >
          Exportar excel
        </b-button>
      </b-card>
      <b-row
        class="mb-2"
      >
        <div class="row align-items-center">
          <div class="col-md-2 mb-2">
            <label>Filtro por forma</label>
            <v-select
              v-model="formaFilter"
              :options="optionsForma"
              multiple
            />
          </div>

          <div class="col-md-2 mb-2">
            <label>Filtro por status recebimento</label>
            <v-select
              v-model="statusFilter"
              :options="optionsStatus"
              multiple
            />
          </div>
          <div class="col-md-2 mb-2">
            <label>Filtro por tipo</label>
            <v-select
              v-model="tipoFilter"
              :options="optionsTipo"
              multiple
            />
          </div>
        </div></b-row>
      <b-table
        small
        striped
        responsive
        :fields="fields"
        :items="listFiltered"
        :per-page="itemPerPage"
        :current-page="currentPage"
      >
        <template #cell(vencimento)="data">
          {{ formatTimezone(data.item.vencimento) }}
        </template>
        <template #cell(recebimento)="data">
          {{ formatTimezone(data.item.recebimento) }}
        </template>
        <template #cell(realizado)="data">
          R$ {{ valorBr(data.item.realizado, true) }}
        </template>
        <template #cell(honorarios)="data">
          R$ {{ valorBr(data.item.honorarios, true) }}
        </template>
        <template #cell(comissao)="data">
          R$ {{ data.item.comissao ? valorBr(data.item.comissao, true) : '' }}
        </template>
        <template #cell(remuneracao_voz)="data">
          R$ {{ valorBr(data.item.remuneracao_voz, true) }}
        </template>
        <template #cell(percentual)="data">
          {{ percentualRemu(data.item.realizado, data.item.remuneracao_voz) }} %
        </template>
      </b-table>
    </b-card>
    <b-pagination
      v-model="currentPage"
      :per-page="itemPerPage"
      :total-rows="totalRows"
      align="center"
      size="sm"
      class="my-0"
    />
  </div>
</template>

<script>
import {
  BCard, BFormInput, BButton, BTable, BCol, BPagination, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { saveAs } from 'file-saver'
import vSelect from 'vue-select'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
// import AppCollapse from 'src/@core/components/app-collapse/AppCollapse.vue'
import axios from '@/../axios-auth'

const ExcelJS = require('exceljs')

export default {
  components: {
    BPagination,
    BTable,
    BCol,
    BButton,
    BCard,
    // BForm,
    BFormInput,
    vSelect,
    AppCollapse,
    AppCollapseItem,
    BAvatar,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      itemPerPage: 20,
      itemsReport: [],
      showListFiltered: false,
      itemsFilter: [],
      statusReceivimentOptions: [
        { label: 'Conta Voz' },
        { label: 'Conta Credor' },
        { label: 'Acordo Voz - Pgt. na Instituição' },
        { label: 'Baixa Administrativa' },
      ],
      date: {
        date_initial: '',
        date_finaly: '',
      },
      creditors: [],
      creditorsFilter: [],
      fields: [
        {
          key: 'id', label: 'ID', class: 'text-center',
        },
        {
          key: 'cliente', label: 'CLIENTE', class: 'text-center',
        },
        {
          key: 'vencimento', label: 'VENCIMENTO', class: 'text-center',
        },
        {
          key: 'recebimento', label: 'RECEBIMENTO', class: 'text-center',
        },
        {
          key: 'numero_parcela', label: 'NÚMERO PARCELA', class: 'text-center',
        },
        {
          key: 'forma', label: 'FORMA', class: 'text-center',
        },
        {
          key: 'status_recebimento', label: 'STATUS RECEBIMENTO', class: 'text-center',
        },
        {
          key: 'realizado', label: 'REALIZADO', class: 'text-center',
        },
        {
          key: 'tipo_remuneracao', label: 'TIPO', class: 'text-center',
        },
        {
          key: 'percentual', label: '% REMURAÇÃO', class: 'text-center',
        },
        {
          key: 'remuneracao_voz', label: 'REMUNERAÇÃO', class: 'text-center',
        },
      ],
      count: {
        liquidado: { total: 0, count: 0 },
        parcelas: 0,
        total: 0,
        clientes: 0,
        percentual: 0,
      },
      formaFilter: [],
      statusFilter: [],
      executivoFilter: [],
      tipoFilter: [],
      percentualRemuneracao: [],
    }
  },
  computed: {
    optionsForma() {
      const items = this.itemsReport
      const forma = []
      items.map(item => {
        item.forma ? forma.push(item.forma) : null
      })
      const itemsFiltered = [...new Set(forma)]
      return itemsFiltered
    },
    optionsStatus() {
      const items = this.itemsReport
      const status = []
      items.map(item => {
        item.status_recebimento ? status.push(item.status_recebimento) : null
      })
      const itemsFiltered = [...new Set(status)]
      return itemsFiltered
    },
    optionsTipo() {
      const items = this.itemsReport
      const tipo = []
      items.map(item => {
        item.tipo_remuneracao ? tipo.push(item.tipo_remuneracao) : null
      })
      const itemsFiltered = [...new Set(tipo)]
      return itemsFiltered
    },
    listFiltered() {
      const items = this.itemsReport
      const { formaFilter: fF, statusFilter: sF, tipoFilter: tF } = this
      let list = this.itemsReport

      if (fF.length > 0 || sF.length > 0 || tF.length > 0) {
        list = []

        // Filtro por Forma
        let newFilterForma = []
        const filterListForma = []
        list.length > 0 ? (newFilterForma = list) : (newFilterForma = items)
        for (let i = 0; i <= fF.length - 1; i++) {
          // eslint-disable-next-line no-loop-func
          newFilterForma.map(item => {
            if (item.forma === fF[i]) {
              filterListForma.push(item)
            }
          })
          list = filterListForma
        }
        // Filtro por Status Recebimento
        let newFilterStatus = []
        const filterListStatus = []
        list.length > 0 ? (newFilterStatus = list) : (newFilterStatus = items)
        for (let i = 0; i <= sF.length - 1; i++) {
          // eslint-disable-next-line no-loop-func
          newFilterStatus.map(item => {
            if (item.status_recebimento === sF[i]) {
              filterListStatus.push(item)
            }
          })
          list = filterListStatus
        }
        // Filtro por Tipo
        let newFilterTipo = []
        const filterListTipo = []
        list.length > 0 ? (newFilterTipo = list) : (newFilterTipo = items)
        for (let i = 0; i <= tF.length - 1; i++) {
          // eslint-disable-next-line no-loop-func
          newFilterTipo.map(item => {
            if (item.tipo_remuneracao === tF[i]) {
              filterListTipo.push(item)
            }
          })
          list = filterListTipo
        }
      }
      this.totalRows = list.length
      return list
    },
  },
  watch: {
    listFiltered: {
      deep: true,
      handler(list) {
        const liquidado = { total: 0, count: 0 }
        let total = 0
        const clientes = []
        const qntParcelas = []
        list.map(item => {
          total += parseFloat(item.realizado)
          clientes.push([...new Set(item.cliente)])
          qntParcelas.push(item.numero_parcela)

          if (item.remuneracao_voz) {
            liquidado.total += parseFloat(item.remuneracao_voz)
            liquidado.count += 1
          }
        })
        this.count.liquidado = liquidado
        this.count.total = total
        this.count.clientes = clientes.length
        this.count.parcelas = qntParcelas.length
        this.count.percentual = ((liquidado.total / total) * 100).toFixed(2)
      },
    },
  },
  async created() {
    const date = new Date()
    const fim = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().slice(0, 10)
    const inicio = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().slice(0, 10)
    this.date.date_initial = inicio
    this.date.date_finaly = fim

    await axios.get('api/v1/credores/listar/false', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      res.data.dados.map(item => {
        this.creditors.push({
          label: item.nome,
          id: item.id,
        })
      })
    })

    // const filter = JSON.parse(localStorage.getItem('filterPrestContas'))
    // // this.filterTo.label = filter.filterToSave
    // this.creditorsFilter = filter.creditors
    // this.date.date_initial = filter.dataInitial
    // this.date.date_finaly = filter.dataFinaly
  },

  methods: {
    percentualRemu(realizado, remuneracao) {
      return ((parseFloat(remuneracao) / parseFloat(realizado)) * 100).toFixed(2)
    },
    async exportFile() {
      const file = await this.getDataToExported()
      if (file) {
        saveAs(file)
      }
    },
    async getDataToExported() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Relatório')
      const itemsPlan = this.listFiltered
      const dataFinal = this.date.date_finaly

      worksheet.columns = [
        { key: 'id', header: 'ID' },
        { key: 'cliente', header: 'CLIENTE' },
        { key: 'vencimento', header: 'VENCIMENTO' },
        { key: 'recebimento', header: 'RECEBIMENTO' },
        { key: 'numero_parcela', header: 'NÚMERO PARCELA' },
        { key: 'forma', header: 'FORMA' },
        { key: 'status_recebimento', header: 'STATUS RECEBIMENTO' },
        { key: 'realizado', header: 'REALIZADO' },
        { key: 'tipo_remuneracao', header: 'TIPO' },
        { key: 'remuneracao_voz', header: 'REMUNERAÇÃO' },
      ]
      const wk1Columns = [
        'realizado',
        'remuneracao_voz',
      ]
      const totais = {
        wk1: {},
      }

      itemsPlan.map(item => {
        worksheet.addRow([
          item.id,
          item.cliente,
          this.formatTimezone(item.vencimento),
          this.formatTimezone(item.recebimento),
          item.numero_parcela,
          item.forma,
          item.status_recebimento,
          parseFloat(item.realizado),
          item.tipo_remuneracao,
          parseFloat(item.remuneracao_voz),
        ])
        wk1Columns.map(w => {
          totais.wk1[w] ? totais.wk1[w] += parseFloat(item[w]) : totais.wk1[w] = parseFloat(item[w])
        })
      })

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })
      const totalRowWk1 = worksheet.addRow({
        realizado: totais.wk1.realizado,
        remuneracao_voz: totais.wk1.remuneracao_voz,
      })
      totalRowWk1.font = {
        name: 'Calibri',
        family: 2,
        bold: true,
        size: 11,
      }
      totalRowWk1.eachCell({ includeEmpty: false }, cell => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F4A460' },
        }
        cell.alignment = {
          horizontal: 'center',
          vertical: 'center',
        }
      })
      wk1Columns.map(key => {
        worksheet.getColumn(`${key}`).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00'
      })

      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], `Relatório Faturamento ${this.formatTimezone(dataFinal)}`, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    resetFilter() {
      this.creditorsFilter = []
      this.filterTo.label = ''
      this.date.date_initial = ''
      this.date.date_finaly = ''
      localStorage.removeItem('filterPrestContas')
    },

    async filter() {
      const cred = []
      this.creditorsFilter.map(creditor => {
        cred.push(creditor.id)
      })

      const filterDetails = {
        credores_id: cred,
        data_inicio: this.date.date_initial,
        data_final: this.date.date_finaly,
      }

      if (filterDetails.data_inicio === '' || filterDetails.data_final === '') {
        this.$swal({
          icon: 'error',
          title: 'Erro na seleção',
          text: 'Selecione todos os campos',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        await axios.post('api/v1/relatorios/backoffice/prestacao_contas', filterDetails, {
          headers: {
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
            accept: 'application/json',
          },
        }).then(res => {
          this.showListFiltered = true
          const liquidado = { total: 0, count: 0 }
          let total = 0
          const clientes = []
          const qntParcelas = []
          const executivoId = localStorage.getItem('executivo_id')
          const items = []
          res.data.dados.map(item => {
            if (item.executivo_id === parseFloat(executivoId)) {
              total += parseFloat(item.realizado)
              clientes.push([...new Set(item.cliente_id)])
              qntParcelas.push(item.numero_parcela)

              if (item.remuneracao_voz) {
                liquidado.total += parseFloat(item.remuneracao_voz)
                liquidado.count += 1
              }
              items.push(item)
              this.count.liquidado = liquidado
              this.count.total = total
              this.count.clientes = clientes.length
              this.count.parcelas = qntParcelas.length
              this.count.percentual = ((liquidado.total / total) * 100).toFixed(2)
              this.itemsReport = items
              this.totalRows = items.length
            }
          })
        }).catch(error => {
          this.$swal({
            title: 'Erro ao buscar os dados!',
            text: error,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })

        const remember = {
          // filterToSave: filterDetails.filtrarPor,
          dataInitial: filterDetails.data_inicio,
          dataFinaly: filterDetails.data_final,
          creditors: this.creditorsFilter,
        }

        localStorage.setItem('filterPrestContas', JSON.stringify(remember))
      }
    },

  },
}
</script>

<style>

</style>
