<template>
  <b-card
    v-if="items"
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Estatísticas</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        Ultima atualização: {{ hoje }}
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="(item, i) in items"
          :key="item.icon"
          xl="3"
          sm="6"
          :class="item.customClass"
          class="cursor-pointer"
          @click="itemClick(i)"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
    <b-modal
      ref="leaderboard"
      title="Ranking"
      hide-footer
      size="lg"
    >
      <div>
        <b-card
          v-for="(itemExecutivo, index) in leaderboard"
          :key="itemExecutivo"
        >
          <b-row align-h="between">
            <b-col
              class="d-flex"
            >
              # {{ index + 1 }}
            </b-col>
            <b-col class="d-flex">
              <span>
                <strong>
                  {{ itemExecutivo.nome }}
                </strong>
              </span>
            </b-col>
            <b-col class="justify-content-end d-flex">
              {{ valorBr(itemExecutivo.valor_total, true) }}
            </b-col>
          </b-row>
        </b-card>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BModal,
} from 'bootstrap-vue'
import axios from '@/../axios-auth'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,

  },
  props: {
    selectCard: {
      type: Function,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      hoje: '',
      items: [
        {
          color: 'light-primary',
          customClass: 'mb-2 mb-xl-0',
          icon: 'AwardIcon',
          subtitle: 'Faturamento',
          title: '',
        },
        {
          color: 'light-info',
          customClass: 'mb-2 mb-xl-0',
          icon: 'UsersIcon',
          subtitle: 'Propostas',
          title: '',
        },
        {
          color: 'light-success',
          customClass: 'mb-2 mb-sm-0',
          icon: 'BoxIcon',
          subtitle: 'Liquidado hoje',
          title: '',
        },
        {
          color: 'light-warning',
          customClass: '',
          icon: 'TrendingUpIcon',
          subtitle: 'Lugar',
          title: '0º',
        },
      ],
      executivo_cobranca: 0,
      leaderboard: [],
      itemClicked: null,
    }
  },
  mounted() {
    this.executivo_cobranca = localStorage.getItem('executivo_id')
    // this.stats_acordos_mes()
    this.formatDate()
    this.getRanking()
  },
  methods: {
    async itemClick(data) {
      if (data === 0) {
        await this.selectCard(0)
      }
      if (data === 1) {
        await this.selectCard(1)
      }
      if (data === 3) {
        this.$refs.leaderboard.show()
      }
    },
    async getRanking() {
      const data = new Date()
      let dia = String(data.getDate()).padStart(2, '0')
      const mes = String(data.getMonth() + 1).padStart(2, '0')
      const ano = data.getFullYear()
      this.hoje = `${dia}/${mes}/${ano}`

      if (mes === '01' || mes === '03' || mes === '05' || mes === '07' || mes === '08' || mes === '10' || mes === '12') {
        dia = '31'
      } else if (mes === '04' || mes === '06' || mes === '09' || mes === '11') {
        dia = '30'
      } else {
        dia = '28'
      }

      const formData = new FormData()
      formData.append('data_inicial', `${ano}-${mes}-01`)
      formData.append('data_final', `${ano}-${mes}-${dia}`)

      this.leaderboard = []

      await axios.post('api/v1/executivo/rank', formData, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(async res => {
          this.items[3].title = `${res.data.dados.findIndex(e => parseInt(e.id) === parseInt(this.executivo_cobranca)) + 1}`.concat('º')

          this.leaderboard = res.data.dados
        })
    },
    // stats_acordos_mes() {
    //   axios
    //     .get(`api/v1/executivo/acordos/mes/${this.executivo_cobranca}`, {
    //       headers: {
    //         Authorization: 'Bearer '.concat(localStorage.getItem('token')),
    //         accept: 'application/json',
    //       },
    //     }).then(async res => {
    //       const { dados } = res.data
    //       const hoje = new Date()
    //       hoje.setHours(0, 0, 0, 0)
    //       // let liquidadosHoje = 0
    //       const acordosAguardando = []
    //       const somaLiquidado = dados.map(item => {
    //         let valorReturn = 0
    //         if (item.status === 'Liquidado') {
    //           const updated = new Date(item.updated_at)
    //           updated.setHours(0, 0, 0, 0)
    //           if (hoje.getTime() === updated.getTime()) {
    //             // liquidadosHoje += 1
    //             valorReturn = parseFloat(item.valor_nominal)
    //           } else {
    //             valorReturn = 0
    //           }
    //         }
    //         return valorReturn
    //       }).reduce((prev, curr) => prev + curr, 0)
    //       dados.map(item => {
    //         if (item.status === 'Aguardando') {
    //           acordosAguardando.push(item)
    //         }
    //       })
    //       this.items[2].title = this.valorBr(somaLiquidado, true)
    //       // this.items[1].title = liquidadosHoje
    //       this.items[1].title = acordosAguardando.length
    //       this.items[0].title = dados.length
    //     })
    // },
    formatDate() {
      const data = new Date()
      const dia = String(data.getDate()).padStart(2, '0')
      const mes = String(data.getMonth() + 1).padStart(2, '0')
      const ano = data.getFullYear()
      const hora = data.getHours()
      const min = data.getMinutes()
      this.hoje = `${dia}/${mes}/${ano} ás ${hora}:${min}`
    },
  },
}
</script>
